import https from "./httpService";

export async function logIt(level, message) {
    const body = { level: level, message: message };
    return https.post("/api/application/LogIt", body);
}

export default {
    logIt: logIt
};
