import React, { Component } from 'react';
import Footer from "../Footer";

const invalidAppUser = {
    backgroundColor: "#e3e3e3",
    margin: "20px",
    padding: "20px",
    borderRadius: "10px"
    
}

class AuthenticationFail extends Component {
    render() {
        return (
            <div>
                
                {/*<div className="primary-jumbo-text jumbotron-lg-content primary-jumbo-text jumbotron-text container jumbotron-notice">*/}
                {/*<h1>Sorry, you are not authorized to access this page. Please click <a href="/">here</a> to go back!</h1>*/}

                <div style={invalidAppUser}>

                <h2>Authentication Fail</h2>

                Sorry, your authenticationFail to eCPS failed. Please contact the <a href="mailto:eCPSAdministrator@niaid.nih.gov" target="_blank">eCPS Admin</a>

                <br />
                </div>
                <Footer />
            </div>

        )
    };
};

export default AuthenticationFail;
