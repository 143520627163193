export const ShowSidebar = true;
export const ShowEditSolicitationSidebar = true;
export const MaxContentSizeAllowed = 406000000; //todo: actual value - 406000000 , testing value -406000
export const Error = "Error";
export const NotAllowed = "NotAllowed";
export const MaxContentSizeLimitExceeded = "MaxContentSizeLimitExceeded";
export const TechPdf = 1;
export const HumanPdf = 2;
export const BusinessPdf = 3;
export const BusinessExcel = 4;
export const NonRDBusinessDocs = 5;
