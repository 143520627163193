import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class ContactUs extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">
                                <a href="mailto:eCPSAdministrator@niaid.nih.gov" alt="contact us" target="_blank" rel="noopener noreferrer">Contact Us</a>
                            </div>
                            <Paper className="content-container">For questions regarding a specific solicitation, contact the primary point of contact listed in the solicitation. For technical questions about this site, contact <a href="mailto:eCPSAdministrator@niaid.nih.gov" target="blank">eCPS Admin</a></Paper>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default ContactUs;