import React, { Component } from "react";
//import { EditSolicitationHeader } from './EditSolicitationHeader';
import { EditSolicitationSideBar } from './EditSolicitationSideBar';
import { SolicitationForm } from './SolicitationForm';
import Footer from "../Footer";
import solicitationService from "../../services/solicitationService";
import Proposal from './Proposal';

export class Solicitation extends Component {

    constructor() {
        super();
        this.state = {
            headerInfo: {},
            solDetails: {},
            isEdit: false,
            showProposal: false,
            solicitationData: {},
            reviewingProposalId: 0
        };

        this.onDataLoaded = this.onDataLoaded.bind(this);
    }

    async componentDidMount() {
        //await this.fetchSolicitationData();

    }

    
    async fetchSolicitationData() {
        const solId = this.props.match.params.id;
        if (solId && solId.toLowerCase() != 'new') {
            solicitationService.getSolicitation(solId)
                .then((response) => {
                    this.setState({
                        solDetails: response.data,
                        headerInfo: response.data.solSolicitationPageHeader,
                        sidebarInfo: response.data.sideProposalPagePanel,
                        isEdit: true,
                        showProposal: false
                    });
                    //console.log('response', response);
                })
                .catch((error) => {
                    //console.error(error);
                    this.setState({ redirect: true });
                });
        }

    }
    showProposalPage = (proposalId)=> {
        this.setState({ showProposal: true, reviewingProposalId: proposalId });
    }
    onEditSolInfoClick = () => {
        this.setStateOnHeaderBtnClick('solicitationDetails');
    }
    onManageStaffUsersAccessClick =() => {
        this.setStateOnHeaderBtnClick('manageStaff');
    }
    setStateOnHeaderBtnClick(defaultTab) {
        this.setState({ showProposal: false, reviewingProposalId: 0, defaultTabOnSolForm: defaultTab });
    }
    onDataLoaded(solicitation) {
        //console.log("Solicitation Data From Child Component:", solicitation);
        this.setState({
            solDetails: solicitation,
            headerInfo: solicitation.solSolicitationPageHeader,
            sidebarInfo: solicitation.sideProposalPagePanel,
            isEdit: true,
            showProposal: false
        })
    }
    render() {
        return (
            <div>
                <div className="overshadow"></div>
                {
                    //this.state.isEdit ? <EditSolicitationHeader headerInfo={this.state.headerInfo} solDetails={this.state.solDetails} onEditSolInfoClick={this.onEditSolInfoClick} onManageStaffUsersAccessClick={this.onManageStaffUsersAccessClick}/> : ""

                }
                <div id="content" className="content">
                    {this.state.isEdit ? <EditSolicitationSideBar panelInfo={this.state.sidebarInfo} showProposal={this.showProposalPage} reviewingProposalId={this.state.reviewingProposalId}/> : ""}
                    {(!this.state.showProposal) ? <SolicitationForm onDataLoaded={this.onDataLoaded} {...this.props} defaultTabOnSolForm={this.state.defaultTabOnSolForm} /> : ""}
                    {this.state.isEdit && this.state.showProposal ? <Proposal proposalId={this.state.reviewingProposalId}/> : ""}
                </div>
                <Footer />
            </div>

        );
    }

}