import React, { Component } from "react";
import solicitationService from "../../services/solicitationService";

import DateTime from "../common/DateTimeConversionMethods";

export class Proposal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proposal: {},
            activities: [],
            files: [],
            vendor: {}
        };
    }
    componentWillReceiveProps(newProps) {
        //console.log('newprops in proposal', newProps);
        if (newProps.proposalId) {
            this.fetchProposalData(newProps.proposalId);
        }
    }
    async componentDidMount() {
        this.fetchProposalData(this.props.proposalId);
    }
    async fetchProposalData(proposalId) {
        var proposal = await solicitationService.getProposalById(proposalId).then((response) => {
            this.setState({ proposal: response.data.proposal, activities: response.data.activities, files: response.data.files, vendor: response.data.vendor });
            //console.log('proposal101', response);
        })
            .catch((error) => {
                //console.error(error);
            });;

    }
    //getDate(dt) {
    //    var d = new Date(dt).toLocaleDateString();
    //    return d;
    //}
    //getTime(dt) {
    //    var t = new Date(dt).toLocaleTimeString();
    //    var hm = t.slice(0, t.lastIndexOf(':')) + ' ' + t.split(' ')[1] + ' ET';
    //    return hm;
    //}
    getClassBasedOnDocType(docType, fileName) {
        var fileExtension = this.getFileExtension(fileName);
        switch (docType) {
            case 1:
                return 'technical-doc';
            case 2:
                if (fileExtension.toLowerCase() === 'pdf')
                    return 'business-doc';
                else
                    return 'business-excel-doc';
            case 3:
                return 'misc-doc';
            default:
        }
    }
    getDocTypeText(docType, fileName) {
        var fileExtension = this.getFileExtension(fileName);
        switch (docType) {
        case 1:
                return 'Technical ' + fileExtension.toUpperCase();
        case 2:
                if (fileExtension.toLowerCase() === 'pdf')
                    return 'Business PDF';
            else
                    return 'Business Excel';
        case 3:
                return 'PHS Human Subject and Clinical Trials' + fileExtension.toUpperCase();
        default:
        }
    }
    getFileExtension(fileName) {
        if (!fileName)
            return null;
        var extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
        //console.log('extension', extension);
        return extension;
    }
    getActivityActionById(actionId) {
        switch (actionId) {
            case 0:
                return 'Error occurred'; 
            case 1:
                return 'Forwarded for review'; 

            case 2:
                return 'Not Forwarded for review'; 

            case 3:
                return 'Added redacted file'; 

            default:
                return '';
        }
    }
    render() {
        var proposal = this.state.proposal;
        var vendor = this.state.vendor;
        return (
            <div className="main-content">
                {proposal &&
                    <div className="container-fluid centered-content">
                        <div className="row tile-height-row">
                            <div className="col-xl-7">
                                <div className="section-heading proposal-information">Proposal Information</div>
                                <div className="tile-section review-proposal tile-height tile-height-setter">
                                    <div className="proposal-card">
                                    <div className="proposal-title">{proposal.proposalName}</div>

                                        <div className="row">
                                            <div className="col-lg-7">
                                                <label className="text-label">Vendor</label>
                                            <p>{vendor?.organization}</p>
                                            </div>
                                            <div className="col-lg-5">
                                                <label className="text-label">Submitted</label>
                                            <p>{this.getDate(proposal.proposalCreateDate)}
                                                <small>{this.getTime(proposal.proposalCreateDate)}</small>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="proposal-card-footer">
                                            <div className="contact">Contact Information</div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <label className="text-label">Name</label>
                                                <p>{vendor?.name}</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label className="text-label">Email</label>
                                                <p>{vendor?.email}</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label className="text-label">Phone</label>
                                                <p>{vendor?.phone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="section-heading">Documents
                                <div className="header-actions">
                                        <button className="MuiButtonBase-root MuiButton-root MuiButton-contained white-btn" tabindex="0" type="button">
                                            <span className="MuiButton-label">
                                                Download All<img src="images/download-blue.svg" alt="download-file" />
                                            </span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </button>
                                    </div>
                                </div>
                                <div className="documents-tile tile-height">
                                    <ul className="chevron-list">
                                        {
                                            this.state.files?.map((x) => (
                                                <li key={x.id} className={`${this.getClassBasedOnDocType(x.type, x.fileName)} outer-link`}>
                                                    <div className="document-details">
                                                        <div className="document-name">{this.getDocTypeText(x.type, x.fileName)}</div>
                                                        <div className="document-file">{x.fileName}</div>
                                                    </div>
                                                    <div className="chevron-right">
                                                        <img src="images/banner-arrow.svg" alt="banner-icon"/>
                                                    </div>
                                                    {x.type === 1 &&
                                                        <a href="#"><small>Upload Redacted Version</small></a>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row tile-height-row">
                            <div className="col-xl-8">
                                <div className="section-heading">My Review</div>
                                <div className="form-section my-review tile-height tile-height-setter">
                                    <form>
                                        <div className="row">
                                            <div className="col-xl-4">
                                                <fieldset className="MuiFormControl-root toggle-switch toggle-med">
                                                    <legend className="MuiFormLabel-root">
                                                        Forward for Technical Review
                                            </legend>
                                                    <div className="MuiFormGroup-root" role="radiogroup" aria-label="investigation">
                                                        <label className="MuiFormControlLabel-root">
                                                            <span className="MuiButtonBase-root MuiIconButton-root jss134 MuiRadio-root MuiRadio-colorSecondary jss135 Mui-checked MuiIconButton-colorSecondary" aria-disabled="false">
                                                                <span className="MuiIconButton-label">
                                                                    <input className="jss137" name="investigation1" type="radio" value="Yes" checked="" />
                                                                </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </span>
                                                            <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Send</span>
                                                        </label>
                                                        <label className="MuiFormControlLabel-root">
                                                            <span className="MuiButtonBase-root MuiIconButton-root jss134 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary" aria-disabled="false">
                                                                <span className="MuiIconButton-label">
                                                                    <input className="jss137" name="investigation1" type="radio" value="No" />
                                                                </span>
                                                                <span className="MuiTouchRipple-root"></span>
                                                            </span>
                                                            <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Do Not Send</span>
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-xl-8">
                                                <div className="MuiFormControl-root jss239 MuiFormControl-fullWidth money-parent">
                                                    <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" htmlFor="standard-adornment-amount">
                                                        Total Direct Cost
                                            </label>
                                                    <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                                                        <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                                                            <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">$</p>
                                                        </div>
                                                        <input type="text" aria-invalid="false" id="standard-adornment-amount" value="" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="MuiFormControl-root MuiTextField-root">
                                                    <div className="MuiInputBase-root MuiInput-root MuiInputBaseControl MuiInputControl MuiInputBase-multiline MuiInput-multiline">
                                                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor="poc-name" id="poc-name-label">
                                                            Comments<small>Optional</small>
                                                        </label>
                                                        <textarea aria-invalid="false" id="poc-name" rows="6" className="MuiInputBase-input MuiInput-input MuiInputBase-inputMultiline MuiInput-inputMultiline"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="form-footer">
                                        <button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" tabindex="0" type="button"><span className="MuiButton-label">Save</span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="section-heading">Activity History</div>
                                <div className="tile-section activity-history tile-height tile-footer-scroll">
                                    <div className="tile-title comment-title">Comment / User</div>
                                    <ul className="circle-line-list scroll-list" tabindex="0">
                                        {this.state.activities.length > 0 ?

                                            this.state.activities.filter(Boolean).map((x) => (
                                                <li key={x.id}>
                                                    <div className="circle-icon"></div>
                                                    <div className="comment">{this.getActivityActionById(x.action)}</div>
                                                    <div className="comment-user-time">
                                                        <div className="comment-user"><strong>{x.userName}</strong></div>
                                                        <div className="comment-time">{DateTime.getDateEt(x.activityTime)} {DateTime.getTimeHhMmWithTimeZoneEt(x.activityTime)}</div>
                                                    </div>
                                                </li>
                                            )) : (<div>No activity found</div>)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Proposal;

