import React, { Component } from "react";
import auth from "../services/authService";

const styleObj = {
    fontSize: "10pt",
    color: "#a12",
    textAlign: "center",
    paddingTop: "0",
    position: "absolute",
    top: "0",
    left: "620px"
}

export class NameTag extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        try {
            const user = auth.getCurrentUser();
            //console.log("user:", user);
            this.setState({ user: user });
        } catch (ex) { }
    }

    render() {
        const userName = this.state.user ? this.state.user.FirstName + " " + this.state.user.LastName + "[" + this.state.user.Email + "]": "";
        return (<span style={styleObj} > { userName }</ span>);
    }
}
