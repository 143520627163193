import React, { Component } from "react";
import { NavMenuAuthenticated } from "./NavMenuAuthenticated";
import { BannerHeaderSecure } from "./BannerHeaderSecure";
import { SiteMaintenanceBanner } from "../common/siteMaintenanceBanner";
import { BrowserCheckBanner } from "../common/browserCheckBanner";
import SessionExpireModal from "./SessionExpireModal";

const isIE = (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1);

export class LayoutSecure extends Component {
    static displayName = LayoutSecure.name;

    constructor(props) {
        super(props);
        this.status = {
        }
    }

    selectChangedH2 = (selectedIcRole) => {
        this.setState({ selectedRole: selectedIcRole });
        this.props.icRoleSelector(selectedIcRole);
    };

    render() {
        return (
            <div role="main">
                {isIE && <BrowserCheckBanner />}
                <SiteMaintenanceBanner />
                <div className="jumbotron jumbotron-header jumbotron-lg" style={{ marginBottom: "0", padding: "6px 0 0 0" }}>
                    <BannerHeaderSecure icTrigger={this.selectChangedH2} />
                    <NavMenuAuthenticated username={this.props.user ? this.props.user.FirstName + " " + this.props.user.LastName + "["
                        + this.props.user.Email + "]" : ""} />
                </div>
                
                <div style={{ marginLeft: "0", marginRight: "0" }} >
                    {this.props.children}
                </div>

            </div>
            );
    }
}
