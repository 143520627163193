import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UrlUtility from "../../utilities/UrlUtility";
import fileDownload from 'js-file-download';
import fileService from "../../services/fileService";

export class RtopAttachmentsModal extends Component {
    constructor(props) {
        super(props);
        this.state = { showDownloadProgress: false, showDownloadAllProgress: false, downloadAttachmentId: -1};
    }

    downloadFile(fileDbId, fileName) {
        const url = UrlUtility.getUrl("solicitations", "Download", fileDbId);
        this.setState({ showDownloadProgress: true, downloadAttachmentId: fileDbId });
        fileService.handleDownload(url, fileName)
            .then(response => {
                fileDownload(response.data, fileName);
                this.setState({ showDownloadProgress: false, downloadAttachmentId: -1 });
            })
            .catch(err => { this.setState({ showDownloadProgress: false, downloadAttachmentId: -1 }); });
    }

    downloadAllAttachments(solicitationId) {
        const url = UrlUtility.getUrl("solicitations", "DownloadAllRtopAttachments", solicitationId);
        const fileName = `solicitation-${solicitationId}-rtop-attachments.zip`;
        this.setState({ showDownloadAllProgress: true});
        fileService.handleDownload(url, fileName)
            .then(response => {
                fileDownload(response.data, fileName);
                this.setState({ showDownloadAllProgress: false });
            })
            .catch(err => { this.setState({ showDownloadAllProgress: false}); });
    }

    renderAttachment(attachment) {
        return <tr className="MuiTableRow-root" key={attachment.id}>
                   <td className="MuiTableData-root" data-label="Initial Attachment">
                       <a href="#" onClick={() => this.downloadFile(attachment.id, attachment.fileName)}>{attachment.fileName}</a>
                       {this.state.showDownloadProgress && this.state.downloadAttachmentId === attachment.id &&
                    <span style={{ marginLeft: '30px' }}><span>Downloading...</span><span className="loader small-loader" style={
{ position: 'absolute' }}></span></span>
                }
                   </td>
                   <td className="MuiTableData-root" data-label="File Size">{attachment.fileSize}</td>
                   <td className="MuiTableData-root" data-label="Date Added">{attachment.dateAdded}</td>
               </tr>;
    }
    
    renderAttachmentGroup(attachmentGroup) {
        const isInitial = attachmentGroup.id === 1;
        const subHeading = isInitial ? "Initial Attachments" : `Additional Attachments ${attachmentGroup.time}`;

        return <React.Fragment key={attachmentGroup.id}>
            <table className="MuiTable-root">
                <thead className="MuiTableHead-root">
                    <tr className="MuiTableRow-root MuiTableRow-head headless-table">
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight" scope="col">{subHeading}</th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight" scope="col">File Size</th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight" scope="col">Date Added</th>
                    </tr>
                </thead>
            </table>
            <div className="table-scroller">
                <table className="MuiTable-root headless-table">
                    <thead className="MuiTableHead-root">
                        <tr className="MuiTableRow-root MuiTableRow-head">
                            <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight" scope="col">Initial Attachments</th>
                            <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight" scope="col">File Size</th>
                            <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight" scope="col">Date Added</th>
                        </tr>
                    </thead>
                    <tbody className="MuiTableBody-root">
                        {attachmentGroup.rtopAttachments && attachmentGroup.rtopAttachments.map(attachment => this.renderAttachment(attachment))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>;
    }

    render() {
        const formData = this.props.formData;
        const parentState = this.props.formData.state;
        const loader = (<div className="loader">Loading...</div>);
        const rtopAttachmentGroups = parentState.rtopAttachmentGroups;
        const hasAttachmentGroups = rtopAttachmentGroups && rtopAttachmentGroups.length > 0;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="rtop-downloads"
            aria-describedby="rtop-attachment-downloads"
            open={parentState.rtopAttachmentsModal.open === 'rtopAttachments'}
            onClose={formData.handleCloseRtop}
            fullWidth={true}
            maxWidth="lg"
            className="attachments-download-modal">
            <DialogTitle className="vendor-modal-title">
                <span className="modal-title" id="add-user-modal-label">Attachments for {parentState.selectedSolicitationTitle}</span>
                <button type="button" className="close" onClick={formData.handleCloseRtop.bind(this)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
           </DialogTitle>
           <DialogContent className="dialog-no-padding-top">
               {parentState.loading && loader}
               <div className="modal-body" id="rtop-downloads">
                    <div className="modal-download-container">
                        {this.state.showDownloadAllProgress ?
                            <button className="MuiButtonBase-root MuiButton-root MuiButton-contained white-btn revise-download" tabIndex="0" type="button"><span>Downloading ...</span><span className="loader small-loader all-attach"></span></button> : 
                        <button
                            tabIndex="0"
                            type="button"
                            onClick={() => this.downloadAllAttachments(parentState.selectedSolicitationId)}
                            disabled={!hasAttachmentGroups}
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained white-btn">
                           <span className="MuiButton-label">
                                <img src="images/download-blue.svg" alt="download-all-attachments"/>Download All Attachments
                           </span>
                           <span className="MuiTouchRipple-root"></span>
                        </button>}
                   </div>
                   <div className="gray-table-container">
                        {hasAttachmentGroups && rtopAttachmentGroups.map(attachmentGroup => this.renderAttachmentGroup(attachmentGroup))}
                   </div>
               </div>
               <div className="dialog-footer float-right success-footer">
                    <button type="button" className="MuiButtonBase-root MuiButton-root btn btn-secondary" onClick={formData.handleCloseRtop}>Close</button>
               </div>
           </DialogContent>
        </Dialog>;
    }
}

export default RtopAttachmentsModal;