import React, { Component } from 'react';
import Footer from "./Footer";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import faqData from "./common/faqDataJson";

class Faq extends Component {

    render() {
        return (
            <div>
                <div className="content">
                    <div className="overshadow"></div>
                    <div className="main-content centered-content">
                        <div className="container-fluid">

                            <div className="section-heading">Frequently Asked Questions</div>
                            <div className="white-accordions faq">

                                {faqData.map((data, key) => {
                                    return (<div className="accordion top-level-accordion" key={key}>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<img src="images/icon-arrow-down-med-blue.svg" alt="arrow right icon" />}
                                                        aria-controls={`panel${data.id}-content`}
                                                        id={`panel${data.id}-header`}
                                                    >
                                                        <div className="sub-header">{data.summary}</div>
                                                    </AccordionSummary>
                                                    <AccordionDetails><span dangerouslySetInnerHTML={{ __html: data["details"] }}></span>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>);
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
};

export default Faq;