import https from "./httpService";

export async function getSubmissionHistoryData() {
    return https.get(
        "api/submission/MySubmissions/"
    );
}


export default {
    getSubmissionHistoryData: getSubmissionHistoryData
    
}; 