import React, { Component } from "react";
import auth from "../services/authService";
import analytics from "../services/analytics";

const styleObj = {
    fontSize: "10pt",
    color: "#a12",
    textAlign: "center",
    paddingTop: "0",
    position: "absolute",
    top: "0",
    left: "620px"
}

export class BannerHeader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        //try {
        //    const user = auth.getCurrentUser();
        //    console.log("user:", user);
        //    this.setState({ user: user });
        //} catch (ex) { }
    }

    render() {
        //const userName = this.state.user ? this.state.user.FirstName + " " + this.state.user.LastName + "[" + this.state.user.Email + "]": "";
        return ( 
            <div>
                <div className="top-header">
                    <div className="nih-details">
                        <a href="#" className="logo-nih" onClick={function(){analytics.trackEvent({ category: 'navigation', action: 'click', label: 'return to external homepage' })}}>
                            <img src="images/logo-nih-mark.svg" className="logo-nih-mark" alt="National Institutes of Health (NIH) Logo" />
                            <img src="images/logo-nih-niaid-keyline-white.svg" className="logo-nih-niaid-text" alt="National Institute of Allergy and Infectious Diseases" />
                        </a><div className="samhsa-logo-container">
		                    <a href="#" className="logo-samhsa">
			                    <img src="images/samhsa_logo.png" className="logo-samhsa" alt="Substances Abuse and Mental Health Services Administration (SAMHSA) logo" /></a>
	                    </div>
	                    <div className="cdc-logo-container">
		                    <a href="#" className="logo-cdc">
			                    <img src="images/cdc_logo.png" className="logo-cdc" alt="Centers for Disease Control and Prevention (CDC) logo" /></a>
	                    </div>
	                    <div className="arpa-logo-container">
		                    <a href="#" className="logo-arpa">
			                    <img src="images/arpa-h_logo_white_notagline.png" className="logo-arpah" alt="Advanced Research Projects Agency for Health (ARPA-H) logo" /></a>
	                    </div>
                        {/*<div className="application-details">*/}
                        {/*    <h1>electronic Contract Proposal Submission</h1>*/}
                        {/*</div>*/}
                        
                    </div>
                </div>
            </div>           
        );
    }
}
