import React, { Component } from 'react';
import { toast } from "react-toastify";
//import _ from 'lodash';
import { sectionNonSpecialInvite, sectionSpecialInvite } from './SolicitationListSections';
//import SolicitationFilter from './secure/SolicitationFilter';
//import Sidebar from './common/sidebar';
import Pagination from '@material-ui/lab/Pagination';
import DelegateStaffModal from "./secure/SolicitationDelegateStaffModal";
import RtopAttachmentsModal from "./secure/SolicitationTaskOrderDownloadsModal";
import solicitationService from "../services/solicitationService";
import HTTPStatusCodes from "../enums/HTTPStatusCodes";
import RecordStatus from "../enums/RecordStatus";
import SolicitationType from "../enums/SolicitationType";

export class SolicitationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            resultsPerPage: 20,
            frontEndPagination: true,
            delegateStaffModal: {
                mode: 'view'
            },
            rtopAttachmentsModal: {
                mode: 'view'
            },
            delegateStaffData: null,
            rtopAttachmentGroups: [],
            selectedSolicitationId: null,
            selectedTaskAreaContractID: null,
            selectedSolicitationTitle: null,
            loading: false,
            delegateStaff: null,
            vendorOrgName: null,
            delegateStaffLoading: false,
            delegateStaffErrors: []
        }
    }

    handleDelegateStaffErrorsChange = async (delegateStaffErrors) => {
        this.setState({ delegateStaffErrors });
    }

    handleDelegateStaffLoadingChange = async (delegateStaffLoading) => {
        this.setState({ delegateStaffLoading });
    }

    handleDelegateStaffChange = async (delegateStaff) => {
        this.setState({ delegateStaff });
    }
    handleOpenStaff = async (solicitationId, taskAreaContractID, isSaving) => {
        const add = Object.assign({}, this.state.delegateStaffModal);
        add.open = 'delegateStaff';
        this.setState({ delegateStaffModal: add, delegateStaffLoading: true });
        
        await solicitationService
            .getSolicitationDelegateStaff(solicitationId, taskAreaContractID)
            .then(result => {
                if (result.status === HTTPStatusCodes.SUCCESS) {
                    //console.log("delegate staff data", result.data);
                    let { vendorOrgName, delegateStaff } = result.data;
                    //console.log("vendorOrgName", vendorOrgName);
                    //console.log("delegateStaff", delegateStaff);
                    //delegateStaff = _.orderBy(delegateStaff, ["roleName", "email"], ["asc", "asc"]);
                    //console.log("delegateStaff-ordered", delegateStaff);
                    let delegateStaffWithStatus = {};
                    if (isSaving) {
                        delegateStaffWithStatus = delegateStaff.map((d, index) => {
                            return d.roleName === { ...d, index, status: RecordStatus.READ_ONLY };
                        });

                        //console.log(" saving", delegateStaffWithStatus);
                    } else {
                        delegateStaffWithStatus = delegateStaff.map((d, index) => {
                            return d.roleName === "Other" ? { ...d, index, status: RecordStatus.EDITABLE } : { ...d, index, status: RecordStatus.READ_ONLY };
                        });

                        //console.log(" not saving", delegateStaffWithStatus);
                    }

                    this.setState({
                        delegateStaffData: result.data,
                        selectedSolicitationId: solicitationId,
                        selectedTaskAreaContractID: taskAreaContractID,
                        delegateStaff: delegateStaffWithStatus,
                        vendorOrgName,
                        delegateStaffLoading: false
                    });
                }

            })
            .catch(ex => {
                //console.log(ex);
                this.setState({ delegateStaffLoading: false });
                toast.error("Error while fetching Delegate Staff for Solicitation.");
            });
    }

    handleCloseStaff = () => {
        var add = Object.assign({}, this.state.delegateStaffModal);
        add.open = '';
        add.openRolePanel = false;
        add.openUserList = false;
        add.ldapUserList = [];
        this.setState({
            delegateStaffModal: add,
            selectedSolicitationId: null,
            selectedTaskAreaContractID: null,
            delegateStaff: null,
            vendorOrgName: null,
            delegateStaffLoading: false,
            delegateStaffErrors: []
        });
    }

    handleOpenRtop = async (solicitationId, solicitationTitle) => {
        const add = Object.assign({}, this.state.rtopAttachmentsModal);
        add.open = 'rtopAttachments';
        this.setState({
            rtopAttachmentsModal: add,
            selectedSolicitationId: solicitationId,
            selectedSolicitationTitle: solicitationTitle
        });

        await solicitationService
            .getSolicitationAttachments(solicitationId)
            .then(result => {
                if (result.status === HTTPStatusCodes.SUCCESS)
                    this.setState({ rtopAttachmentGroups: result.data });
            })
            .catch(ex => {
                //console.log(ex);
                toast.error("Error while fetching attachments.");
            });
    }

    handleCloseRtop = () => {
        var add = Object.assign({}, this.state.rtopAttachmentsModal);
        add.open = '';
        add.openRolePanel = false;
        add.openUserList = false;
        add.ldapUserList = [];
        this.setState({ rtopAttachmentsModal: add, selectedSolicitationId: null });
    }

    solicitationTypeClass = (noticeTypeName) => {
        switch (noticeTypeName) {
            case 'Solicitation':
                return 'solicitation';
            case 'Combined Synopsis/Solicitation':
                return 'combined';
            case 'Sources Sought':
                return 'sources';
            case 'Presolicitation':
                return 'pre-notice';
            case 'Special Notice':
                return 'pre-notice';
            case 'Award':
                return 'award';
            default:
                return 'other';
        }
    };
    setPageNumber = (value) => {
        this.setState({ currentPage: value });
    }
    //getTimeHhMmWithTimeZoneString(dt) {
    //    var hm = dt.slice(0, dt.lastIndexOf('E')) + ' [ET]';

    //    return hm;
    //}

    renderInvitedAttachmentsButtons(solicitation) {
        return <div className="solicitation-toggleable-container">
            <span className="yellow-pill invite-pill"><img src="images/star-yellow.svg" alt="Invited Icon" />Invited</span>
            <button className="link-btn" onClick={() => this.handleOpenRtop(solicitation.solicitationID, solicitation.solicitationTitle)}>
                Attachments<img src="images/download-blue.svg" alt="download-attachments" />
            </button>
            {(solicitation.solicitationTypeId === SolicitationType.Other) ? (<span className="project-banner-sol-number">{solicitation.solicitationNumber}</span>) : ''}
        </div>;
    }

    renderSpecialInvitedAttachmentsButtons(solicitation) {
        return <div className="solicitation-toggleable-container" style={{paddingRight: "65px"} }>
            <span className="yellow-pill invite-pill"><img src="images/star-yellow.svg" alt="Invited Icon" />Invited</span>
            {/*<button className="link-btn" onClick={() => this.handleOpenRtop(solicitation.solicitationID, solicitation.solicitationTitle)}>*/}
            {/*    Attachments<img src="images/download-blue.svg" alt="download-attachments" />*/}
            {/*</button>*/}
            {/*{(solicitation.solicitationTypeId === SolicitationType.ARPA) ? (<span className="project-banner-sol-number">{solicitation.solicitationNumber}</span>) : ''}*/}
        </div>;
    }

    renderSamUrl(solicitation) {
        if (solicitation.samUrl == "" || solicitation.solicitationNumber == "")
            return "";
        return <a href={solicitation.samUrl} target="_blank" className="project-link">{solicitation.solicitationNumber}</a>;
    }

    render() {
        let totalPages = Math.ceil(this.props.solList.length / this.state.resultsPerPage);
        let startIndex = (this.state.currentPage - 1) * this.state.resultsPerPage;
        let endIndex = startIndex + this.state.resultsPerPage;
        let solsCurrentPage = this.state.frontEndPagination ? this.props.solList.slice(startIndex, endIndex) : this.props.solList;
        
        let updatePage = (event, newPageNumber) => {
 
            this.setPageNumber(newPageNumber);

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        };
        return (

            <div className="container-fluid solicitation-landing external">
 
                {this.state.frontEndPagination && (<div className="pagination"><Pagination count={totalPages} page={this.state.currentPage} onChange={updatePage} aria-label="top pagination naviation" /></div>)}



                {solsCurrentPage.map((sol, index) => {
                    return (<div className={`project-banner-preview combined project-banner-preview ${this.solicitationTypeClass(sol.noticeTypeName)}`} key={index}>
                        {sol.specialInvites.length === 0 && sectionNonSpecialInvite(this, sol)}
                        {sol.specialInvites.length > 0 && sectionSpecialInvite(this, sol) }
                    </div>)})               
                }

                {this.state.frontEndPagination && (<div className="pagination"><Pagination count={totalPages} page={this.state.currentPage} onChange={updatePage} aria-label="bottom pagination navigation" /></div>)}

                <DelegateStaffModal formData={this} />
                <RtopAttachmentsModal formData={this} />
            </div>
        );
    }
}

export default SolicitationList;