import React, { Component } from "react";
import { ShowEditSolicitationSidebar } from "../common/constants";

export class EditSolicitationSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proposals: [],
            searchText: '',
            reviewedFilter: false,
            pendingFilter: false,
            reviewingProposalId: 0
        };
    }
    componentWillReceiveProps(newProps) {
        if (newProps.reviewingProposalId === 0) {
            this.setState({ reviewingProposalId: newProps.reviewingProposalId });
        }
    }
    componentDidMount() {
        const isSidebarShown = !!localStorage.getItem(ShowEditSolicitationSidebar);
        this.setState({ ShowSidebar: isSidebarShown !== ShowEditSolicitationSidebar });
    }
    getDate(dt) {
        var d = new Date(dt).toLocaleDateString();
        return d;
    }
    onPropSearchTextChange(text) {
        this.setState({ searchText: text});
    }
    search(proposal) {
        var result = false;
        if (proposal) {
            var titleResult = proposal.proposalName?.toLowerCase().includes(this.state.searchText.toLowerCase());
            var instituteResult = proposal.institution?.toLowerCase().includes(this.state.searchText.toLowerCase());
   
            result = (titleResult || instituteResult);
        }
        return result;
    }
    filter(proposal) {
        var result = false;
        if (proposal) {
            if (this.state.pendingFilter) {
                result = (proposal?.status === 0);
            }
            if (this.state.reviewedFilter) {
                result = (proposal?.status === 2);
            }
            if (!this.state.pendingFilter && !this.state.reviewedFilter) {
                result = true;
            }
        }
        return result;
    }
    renderProposalStatusSwitch(param) {
        switch (param) {
        case 0:
                return 'Pending';
        case 1:
                return 'Not Forwarded';
        case 2:
            return 'Forwarded';
        default:
            return '';
        }
    }
    renderProposalStatusImageSwitch(param) {
        switch (param) {
        case 0:
                return 'images/status-pending-circle.svg';
        case 1:
                return 'images/status-denied-circle.svg';
        case 2:
                return 'images/status-complete-circle.svg';
        default:
            return '';
        }
    }
    render() {
        const toggleSidebar = () => {
            this.setState({ ShowSidebar: this.state.ShowSidebar !== ShowEditSolicitationSidebar });
            localStorage.setItem(ShowEditSolicitationSidebar, this.state.ShowSidebar);

        };
        return (
            <div className={`sidebar float-sidebar ${this.state.ShowSidebar !== ShowEditSolicitationSidebar ? 'closed' : ''}`}>
                <button className="btn sidebar-trigger trigger-md" onClick={() => { toggleSidebar() }}>Proposals<img src="./images/icon-arrow-down-light.svg" alt="icon proposals" /></button>
                <div className="sidebar-header">
                    <div className="counters">
                        <div className={`counter pending ${this.state.pendingFilter == true ? 'active' : ''}`} onClick={() => { this.setState({ pendingFilter: !this.state.pendingFilter, reviewedFilter: false})}}>
                            <div className="counted">{this.props.panelInfo?.proposals?.filter((x) => x?.status == 0).length}</div>
                            Pending
                    </div>
                        <div className={`counter reviewed ${this.state.reviewedFilter == true ? 'active' : ''}`} onClick={() => { this.setState({ reviewedFilter: !this.state.reviewedFilter, pendingFilter: false }) }}>
                            <div className="counted">{this.props.panelInfo?.proposals?.filter((x) => x?.status == 2).length}</div>
                            Reviewed
                    </div>
                    </div>
                    <form>
                        <div className="MuiFormControl-root MuiTextField-root">
                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true">
                                Search Proposals
                        </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <input aria-invalid="false" id="poc-email" required="" onChange={(e) => this.onPropSearchTextChange(e.target.value)} type="text" className="MuiInputBase-input MuiInput-input" defaultValue="" />
                            </div>
                            <i className="search-icon"></i>
                        </div>
                    </form>
                </div>
                <div className="sidebar-list">
                    {
                        this.props.panelInfo?.proposals?.filter(Boolean).filter(x => this.search(x)).filter(x => this.filter(x)).map((x) => (
                            <div key={x.proposalID} className={`sidebar-listing ${this.state.reviewingProposalId == x.proposalID ? 'active-listing' : ''}`} onClick={() => {
                                this.setState({ reviewingProposalId: x.proposalID });
                                this.props.showProposal(x.proposalID);
                            }}>
                                <div className="list-head">
                                    {x.proposalName}
                                </div>
                                <div className="list-body">
                                    <p>{x.institution}</p>
                                    <strong>{x.proposerName}</strong>
                                </div>
                                <div className="list-footer">
                                    <div className="approval">
                                        <i className="pending"></i>
                                        <div className="approval-status">
                                            <img src={this.renderProposalStatusImageSwitch(x.status)} alt="status-icon" />
                                            <small>{this.renderProposalStatusSwitch(x.status)}</small>
                                            <p >{this.state.reviewingProposalId == x.proposalID ?'Reviewing Now': ''}</p>
                                        </div>
                                    </div>
                                    <div className="date">
                                        <small>Submitted</small>
                                        <p>{this.getDate(x.proposalCreateDate)}</p>
                                    </div>
                                </div>
                            </div>
                            
                            ))}
                 
                </div>
            </div>
        );
    }
}