import React from "react";
import PropTypes from 'prop-types';

export default function ErrorRedirect(statusCode) {
    if (statusCode === 401)
        window.location = "/AuthenticationFail";
    else if (statusCode === 403)
        window.location = "/appAccessDenied";
    else if (statusCode === 423)
        window.location = "/accountLocked";
    else //To be continue
        window.location = "/error500";
};




