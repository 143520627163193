import React, { Component } from "react";

export class SiteMaintenanceBanner extends Component {

    state = {
        showBanner: true
    }

    closeBanner = () => {
        this.setState({
            showBanner: false
        });
    }

    render() {
        const maintenanceMessage = this.props.maintenanceMessage;
        const message = maintenanceMessage !== "" ? maintenanceMessage : "Website is temporarily unavailable.";
        return (
            <div>
                {this.state.showBanner &&
                    <div className="banner-site-status">
                        <div>
                            <img src="/images/icon-exclamation-circle-orange.svg" alt="icon warning" />
                            <div dangerouslySetInnerHTML={{ __html: message }} ></div>
                        </div>
                        <div>
                            <img src="/images/x-gray.svg" alt="icon close" onClick={this.closeBanner} />
                        </div>
                    </div >}
            </div>
        );
    }
}