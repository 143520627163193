import React, { Component } from 'react';
import Footer from "../Footer";

const accessDeniedStyle = {
    backgroundColor: "#e3e3e3",
    margin: "20px",
    padding: "20px",
    borderRadius: "10px"
    
}

class AppAccessDenied extends Component {
    render() {
        return (
            <div>
                
                {/*<div className="primary-jumbo-text jumbotron-lg-content primary-jumbo-text jumbotron-text container jumbotron-notice">*/}
                {/*<h1>Sorry, you are not authorized to access this page. Please click <a href="/">here</a> to go back!</h1>*/}

                <div style={accessDeniedStyle}>

                <h2>Access Denied</h2>
                    Sorry, you are not authorized to access this page. Please click <a href="/logout">here</a> to go back!
                <br />
                </div>
                <Footer />
            </div>

        )
    };
};

export default AppAccessDenied;
