import React, { Component } from "react";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";


import ProposalSubmissionHistory from './ProposalSubmissionHistory';
import SolicitationType from "../../enums/SolicitationType";

export class SolicitationSubmissionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            isActive:false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    async componentWillMount() {
        const currentTime = moment().tz("America/New_York").format();
        //console.log("current time", currentTime);

        if (this.props.solicitation.solicitationTypeId === SolicitationType.ARPA) {
            if (currentTime < this.props.solicitation.solicitationProposals[0]?.proposal?.specialInvite?.responseDeadline) {
                this.setState({ isActive: true });
            }
        }
        else {
            if (currentTime < this.props.solicitation.solicitationResponseDeadline) {
                this.setState({ isActive: true });
            }
        }
        

        //console.log("solicitation- data", this.props.solicitation);
    }

    render() {
        const sol = this.props.solicitation;

        const specialInviteId = sol?.solicitationProposals[0]?.proposal?.specialInviteId;

        //const isBaseSol = (sol?.isBaseSolicitation == null || sol?.isBaseSolicitation === true);
        const isBaseSol = (sol?.solicitationTypeId === SolicitationType.Base);
        //const isTaskOrderSol = !isBaseSol;
        var content = (
            <div className="accordion top-level-accordion">
                <Accordion expanded={this.state.expanded} onChange={this.handleChange}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}



                        aria-controls={`panel-${sol.solicitationID}-content`}
                        id={sol.solicitationID}
                    >
                        {/* <Typography className="accordion top-level-accordion"></Typography> */}
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-header-top">
                                        <div className="card-header-title">{sol.solicitationTitle}</div>
                                    
                                    {/*new/alternate proposal*/}
                                    </div>

                                {sol.solicitationProposals[0].proposal.specialInvite?.baseProposal != null &&
                                    <div style={{ position: "absolute", top: "48px", opacity: "0.8" }}>
                                        <span style={{fontWeight: "bold", paddingRight: "15px"}}>Abtract Title</span>
                                        {sol.solicitationProposals[0].proposal.specialInvite?.baseProposal?.proposalName}
                                    </div>
                                }
                                

                                    <div className="white-accordion-table">
                                    <div className="column-block">
                                        {/*link to prop*/}
                                            
                                        </div>
                                        <div className="column-block">
                                            <div className="column-label">Closing Date</div>

                                        {sol.solicitationTypeId !== SolicitationType.ARPA &&
                                            <div className="column-data">{sol.responseDeadlineDateString}<small>{sol.responseDeadlineTimeString}</small></div>
                                        }

                                        {sol.solicitationTypeId === SolicitationType.ARPA && sol.solicitationProposals[0]?.proposal?.specialInvite?.responseDeadlineDateString != null &&
                                            <div className="column-data">{sol.solicitationProposals[0].proposal.specialInvite.responseDeadlineDateString}
                                                <small>{sol.solicitationProposals[0].proposal.specialInvite.responseDeadlineTimeString}</small></div>
                                        }
                                            
                                        </div>
                                        <div className="column-block">
                                            <div className="column-label">Notice Type</div>
                                        <div className="column-data">{sol.noticeType}</div>
                                        </div>
                                        <div className="column-block">
                                            <div className="column-label">Agency</div>
                                        <div className="column-data">{sol.solicitationTargetIC}</div>
                                        </div>
                                        <div className="column-block">
                                            <div className="column-label">Contract Specialist</div>
                                        <div className="column-data">{sol.primaryCs}</div>
                                        </div>
                                    <div className="column-block">
                                        {this.state.expanded &&
                                            <div className="column-link accordion-arrow accordion-trigger open" type="button" data-toggle="collapse" data-target={`#question-${sol.solicitationID}`} aria-controls={`question-${sol.solicitationID}`}>
                                                                        Hide Proposal Details<img src="./images/icon-arrow-right-blue.svg" alt="hide-proposal-details" />
                                                                </div>
                                        }
                                        {!this.state.expanded &&
                                            <div className="column-link accordion-arrow accordion-trigger closed" type="button" data-toggle="collapse" data-target={`#question-${sol.solicitationID}`} aria-controls={`question-${sol.solicitationID}`}>
                                                View Proposal Details<img src="./images/icon-arrow-up-blue.svg" alt="view-proposal-details" />
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="collapse show inner-accordion" id={`question-${sol.solicitationID}`} data-parent={`#question-${sol.solicitationID}-accordion`}>
                            {
                                sol.solicitationProposals.map((item) => (
                                    <ProposalSubmissionHistory key={item.id} proposal={item.proposal} isRd={sol.solicitationIsRD} isActive={this.state.isActive} />
                                ))
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>

                {this.state.isActive && specialInviteId == null &&
                    <Link to={`/submitProposal/${sol.solicitationID}`}>
                        <button className="MuiButtonBase-root MuiButton-root new-alternate-btn submission-history-button" tabIndex="0" title={`new/alternate proposal for ${sol.solicitationID}`} >
                            <span className="MuiButton-label">
                                New/Alternate Proposal
                                                 </span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </Link>
                }

                {this.state.isActive && specialInviteId > 0 &&
                    <Link to={`/submitSpecialProposal/${sol.solicitationID}/${specialInviteId}`}>
                        <button className="MuiButtonBase-root MuiButton-root new-alternate-btn submission-history-button" tabIndex="0" title={`new/alternate proposal for ${sol.solicitationID}`} >
                            <span className="MuiButton-label">
                                New/Alternate Proposal
                                                 </span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </Link>
                }

                { isBaseSol && this.props.solicitation.samUrl.endsWith("view")
                    ? (<div className="submission-history-link">
                        <div className="column-link">
                        <a href={this.props.solicitation.samUrl} target="_blank">{sol.solicitationNumber}<img src="./images/exitlink.svg" alt="exit-link" /></a>
                        </div>
                    </div>) : (<div className="submission-history-link">
                        <div className="column-data">{sol.solicitationNumber}</div>
                    </div>)
                }

            </div>
        );

        return (content);
    }
}

export default SolicitationSubmissionHistory;