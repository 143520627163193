import https from "./httpService";
var globalCancellation = null;

export async function postSubmitGateway(user, solicitationId, buttonType, cancelSubmission) {
    globalCancellation = https.cancelToken.source();

    return https.post("api/proxy/submitGateway/", { user: user, solicitationId, buttonType: buttonType }, {
        cancelToken: globalCancellation.token
    });
}

export async function getSolProposalSubmissionData(solId) {

    if (!solId) {
        return null;
    }

    return https.get(
        "api/solicitations/SolProposalSubmission/" + solId
    );
}

export async function getSpecialSolProposalSubmissionData(solId, specialInviteId) {
    if (!solId) {
        return null;
    }

    return https.get(
        "api/solicitations/SpecialSolProposalSubmission/" + solId + "/" + specialInviteId
    );
}

export async function getSolProposalSubmissionDataByProposalID(solId) {

    if (!solId) {
        return null;
    }

    return https.get(
        "api/solicitations/SolProposalSubmissionDataByProposalId/" + solId
    );
}
export async function postSubmitProposalFileUpload(pageGuid, data) {
  //console.log('please please');
   const result = https.post("api/file/" + pageGuid + "/upload",
        data,
        {
          onUploadProgress: progressEvent => console.log('hello there please work', progressEvent.loaded)
            // receive two    parameter endpoint url ,form data
        
        });
    return result;
}

export async function postSubmitProposalFileDelete(pageGuid, fileGuid) {
    //console.log("PageGuid", pageGuid);
    //console.log("FileGuid", fileGuid);
    return https.post("api/file/" + pageGuid + "/delete?fileGuid=" + fileGuid);
    
}
export async function getPageHeaderInfo(solId) {

    if (!solId) {
        return null;
    }

    return https.get(
        "api/solicitations/ProposalRelatedPageHeaderInfo/" + solId
    );
}

export async function getSpecialPageHeaderInfo(solId, specialInviteId) {

    if (!solId || specialInviteId <= 0) {
        return null;
    }

    return https.get(
        "api/solicitations/ProposalSpecialPageHeaderInfo/" + solId + "/" + specialInviteId
    );
}

export async function vendorContactInfo(proposalId) {

    if (!proposalId) {
        return null;
    }

    return https.get(
        "api/solicitations/VendorContactInfo/" + proposalId
    );
}
export async function postSubmitProposal(submissionType, solicitationId, proposal, surroundingState, endPoint, actionType, cancelSubmission) {

  // IF YOU'RE TRYING TO OBSERVE PROGRESS BAR WHEN YOU ONLY SEE 90%
    // throttle speed with network dev tool - set to Slow 3G
    // get file size that you're uploading
    // divide loaded amount and total file size
    // add element on the page just to display how much has loaded - figure out how to output that amount as well as the request
    // add variable as object with progress
    // TIP: after you finish looking at it, change network speed back to Online, otherwise you'll have slow loading for the rest of the site

    if (cancelSubmission) {
        globalCancellation.cancel("CANCEL_UPLOAD");
        return false;
    }
    
    let filesToUpload = [];
        //newFile;
    const formData = new FormData();

    if (submissionType === 'newProposal') {
        proposal.uploadedFiles.forEach((item) => {
            //var newFile = new File([item.file], item.fileType + "_" + item.file.name);
            var newFile = { file: item.file, name: item.fileType + "_" + item.file.name };
            filesToUpload.push(newFile);
      });
    } else if (submissionType === 'editProposal') {
        proposal.uploadedFileChanges.forEach((item) => {    
            //File object is not working on Edge browser
            //var newFile = new File([item.file], item.fileType + "_" + item.fileDbId + "_" + item.file.name);
            var newFile = { file: item.file, name: item.fileType + "_" + item.fileDbId + "_" + item.file.name };
            filesToUpload.push(newFile);
            
            //formData.append('proposalId', proposal.proposalId);
      });
    }
    
    formData.append('proposalId', proposal.proposalId);
    formData.append('proposalName', proposal.proposalName);  
    formData.append('specialInviteId', proposal.specialInviteId);
    formData.append('selectedFastTrackValue', proposal.selectedFastTrackValue);
    formData.append('selectedPhase2Value', proposal.selectedPhase2Value);

    //formData.append('proposalName2', "TESTValue2");
    
    // let header = {
    //   onUploadProgress: function(progressEvent) {
    //       let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //     if (percentCompleted <= 90) {
    //       surroundingState.setState({loadedAmount: percentCompleted});
    //     } else {
    //       surroundingState.setState({backEndStatus: 'loading server'});
    //       surroundingState.setState({loadedAmount: 90});
    //     }
    //     return percentCompleted;
    //   }
    // };

    // const cancelTokenSource = cancelToken.source();
    globalCancellation = https.cancelToken.source();

    Array.from(filesToUpload).map((file, index) => {
        return formData.append('file' + index, file.file, file.name);
        //return formData.append('file' + index, file, file.name);
    });

    //console.log('inside post submit');

    let startCountDown = false;

    function setLoadedAmount () {
        if (!startCountDown) {
            startCountDown = true;
            let count = 1;
            let countdownInterval = null;

            surroundingState.setState({backEndStatus: 'loading server'});
            surroundingState.setState({loadedAmount: 90 + count});

            countdownInterval = setInterval(() => {
                count++;

                if (count == 9) {
                    clearInterval(countdownInterval);
                }

                surroundingState.setState({backEndStatus: 'loading server'});
                surroundingState.setState({loadedAmount: 90 + count});
            }, 1000);
        }
    }

    let request = await https.post(endPoint + solicitationId + actionType, formData, {
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentCompleted <= 90) {
            surroundingState.setState({loadedAmount: percentCompleted});
          } else {
            setLoadedAmount();
          }

          return percentCompleted;
        },
        cancelToken: globalCancellation.token
    });

    // if (cancelSubmission) {
    //   cancelTokenSource.cancel();
    // }

    return request;

}

export async function postUpdateProposal(solicitationId,proposal) {
  // THIS FUNCTION IS NO LONGER IN USE. USE POST SUBMIT PROPOSAL IF YOU'RE SUBMITTING TO DATABASE
  //console.log('inside update function - NO LONGER IN USE');
  //  console.log("Update proposal solicitation id", solicitationId);
  //  console.log("Proposal", proposal);

    let filesToUpload = [];
    const formData = new FormData();
    

    proposal.uploadedFileChanges.forEach((item) => {
        var newFile = new File([item.file], item.fileType + "_" +item.fileDbId + "_" + item.file.name);
        filesToUpload.push(newFile);
    });
    //console.log("filesToUpload", filesToUpload);

    formData.append('proposalName', proposal.proposalName);
    formData.append('proposalId', proposal.proposalId);

    Array.from(filesToUpload).map((file, index) => {
        return formData.append('file' + index, file, file.name);
    });

    //console.log("endpoint", "api/solicitations/" + solicitationId + "/updateProposal/");
    //console.log("submission formData", formData);
    return https.post("api/solicitations/" + solicitationId + "/updateProposal/", formData);

}
export async function GetReviseProposalData(proposalId) {

    if (!proposalId) {
        return null;
    }

    return https.get(
        "api/solicitations/ReviseProposal/" + proposalId
    );
}

export async function guid() {
    const { data } = await https.get("api/file/genGui");
    return data;
}

function isGUID(uuid) {
    let s = "" + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
        return false;
    }
    return true;
}

export default {
    postSubmitGateway: postSubmitGateway,
    getSolProposalSubmissionData: getSolProposalSubmissionData,
    getSpecialSolProposalSubmissionData: getSpecialSolProposalSubmissionData,
    getSolProposalSubmissionDataByProposalID: getSolProposalSubmissionDataByProposalID,
    postSubmitProposalFileUpload: postSubmitProposalFileUpload,
    getPageHeaderInfo: getPageHeaderInfo,
    getSpecialPageHeaderInfo: getSpecialPageHeaderInfo,
    vendorContactInfo: vendorContactInfo,
    postSubmitProposalFileDelete: postSubmitProposalFileDelete,
    postSubmitProposal: postSubmitProposal,
    postUpdateProposal: postUpdateProposal,
    GetReviseProposalData: GetReviseProposalData,
    guid: guid,
    isGUID: isGUID,
    globalCancellation: globalCancellation
}; 