import axios from 'axios'
import { toast } from "react-toastify";
//import fileDownload from 'js-file-download';

async function handleDownload(url, filename) {
    return axios.get(url, { responseType: 'blob' });
}

function getDownloadUrl(id) {
    return '/api/file/download/' + id;
}

export default {
    handleDownload: handleDownload,
    getDownloadUrl: getDownloadUrl
};