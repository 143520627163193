import React, { Component } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { NameTag } from "./NameTag";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: 'collapse'
    };
  }

  toggleNavbar() {
    let navCollapse;

    if (this.state.collapsed) {
      navCollapse = '';
    } else if (!this.state.collapsed) {
      navCollapse = 'collapse';
    }

    this.setState({
        collapsed: navCollapse
    });
  }

  render() {
      const username = this.props.username;

      return (
          <nav className="navbar navbar-expand-md navbar-dark">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleNavbar}>
                  <span className="navbar-toggler-icon"></span>
              </button>

              <div className={`${this.state.collapsed} navbar-collapse`} id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                      <li className="nav-item">
                          <Link className="nav-link" to="/">Home<span className="sr-only"></span></Link>
                      </li>
                      
                      <li className="nav-item">
                          <Link className="nav-link" to="/About">About</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/HowToSubmit">How to Submit</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/faq">FAQ</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/contactUs">Contact</Link>
                      </li>
                  </ul>
              </div>
          </nav>
      );
  }
}
