import React, { Component } from "react";
import IdleTimer from 'react-idle-timer';
import { Button } from 'react-bootstrap';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import config from "../../config.json";
import authService from "../../services/authService";

export class SessionExpireModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        
    }

    

    render() {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="session-expire"
                aria-describedby="session-expire"
                open={this.props.showModal === true}
                onClose={this.props.handleClose}
                fullWidth={true}
                maxWidth="xs">
               
                <DialogTitle className={`vendor-modal-title`}>
                    <span className="modal-title" id="session-expire-modal-label">User Session Expiring</span>
                    <button type="button" className="close" onClick={this.props.handleClose} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </DialogTitle>
                <DialogContent className="dialog-no-padding-top">
                    <div className={`modal-body`}>
                        Your session is about to expire. To keep working, click <strong>Stay on Page</strong> button. To end session, click <strong>Go to Login Page</strong>.
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="dialog-footer">
                        <Button
                            type="button"
                            className="MuiButtonBase-root MuiButton-root btn btn-secondary"
                            id="goToLogin"
                            onClick={this.props.handleLogout}>
                            Go to Login Page
                        </Button>
                        <Button
                            type="button"
                            className="MuiButtonBase-root MuiButton-root btn btn-primary"
                            id="stayOnPage"
                            onClick={this.props.handleRefresh}>
                            Stay on Page
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default SessionExpireModal;
