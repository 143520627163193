import React from 'react';

const Select = ({ name, label, options, error, value, isRequired, onChange }) => {
  return (   
        <div className="col-lg-3 col-md-6">
            <div className="MuiFormControl-root MuiTextField-root">
                <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" htmlFor={name} id={`${name}-label`}>
                    {label}
                </label>
                <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                    <select name={name} id={name} value={value} onChange={onChange} className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input">
                      {isRequired && <option value='' />}
                      {options.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                     </option>
                    ))}
                    </select>
                    <img src="images/icon-arrow-down-light.svg" className="select-down-arrow" alt="select arrow" />
                </div>
          </div>
          {isRequired && error && <small className="text-danger">{error}</small>}
        </div>
  );
};

export default Select;