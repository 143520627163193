import React, { Component } from "react";

import { Button, Image } from 'react-bootstrap';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { toast } from "react-toastify";

import Joi from 'joi-browser';

import Input from "../common/input";

import RecordStatus from "../../enums/RecordStatus";
import solicitationService from "../../services/solicitationService";
import HTTPStatusCodes from "../../enums/HTTPStatusCodes";

export class DelegateStaffModal extends Component {
    constructor(props) {
        super(props);
        //this.state = this.createInitialState();
        this.state = {
            vendorOrgName: "",
            delegateStaff: [],
            errors: [],
            loading: false
        }
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
        this.addNewDelegateStaff = this.addNewDelegateStaff.bind(this);
    }

    createInitialState() {
        //console.log("initial state data from props", props);
        return {
            vendorOrgName: "",
            delegateStaff: [],
            errors: []
        };
    }

    //componentWillReceiveProps(nextProps) {
    //    const parentState = nextProps.formData.state;
    //    const delegateStaffData = parentState.delegateStaffData;
    //    this.setVendorOrgName(delegateStaffData);
    //    this.setDelegateStaff(delegateStaffData);
    //    this.clearValidationErrors();
    //}

    static getDerivedStateFromProps(props, state) {
        if (
            (props.formData.state.delegateStaff && props.formData.state.delegateStaff !== state.delegateStaff)
            || (props.formData.state.vendorOrgName !== state.vendorOrgName)
            || (props.formData.state.delegateStaffLoading !== state.loading)
            || (props.formData.state.delegateStaffErrors !== state.errors)
        ) {
            return {
                delegateStaff: props.formData.state.delegateStaff,
                vendorOrgName: props.formData.state.vendorOrgName,
                loading: props.formData.state.delegateStaffLoading,
                errors: props.formData.state.delegateStaffErrors
            };
        }
        return null;
    }

    //setVendorOrgName(delegateStaffData) {
    //    if (delegateStaffData == null)
    //        return;
    //    const vendorOrgName = delegateStaffData.vendorOrgName;
    //    this.setState({ vendorOrgName: vendorOrgName });
    //}

    //setDelegateStaff(delegateStaffData) {
    //    if (delegateStaffData == null)
    //        return;
    //    const delegateStaff = delegateStaffData.delegateStaff;
    //    const delegateStaffWithStatus = delegateStaff.map((d, index) => {
    //        return d.roleName === "Other" ? { ...d, index, status: RecordStatus.EDITABLE } : { ...d, index, status: RecordStatus.READ_ONLY };
    //    });
    //    this.setState({ delegateStaff: delegateStaffWithStatus });
    //}

    //clearValidationErrors() {
    //    this.setState({ errors: [] });
    //}

    addNewDelegateStaff() {
        const index = this.getLastUserIndex(this.state.delegateStaff);
        //console.log("addNewDelegateStaff- new index", index);
        const newUser = this.createNewUser(index);
        const newDelegateStaff = [...this.state.delegateStaff, newUser];
        //this.setState({ delegateStaff: newDelegateStaff });
        this.props.formData.handleDelegateStaffChange(newDelegateStaff);
    }

    createNewUser(index) {
        return {
            "index": index,
            "userAccessId": 0,
            "firstName": "",
            "lastName": "",
            "roleName": "Other",
            "email": "",
            status: RecordStatus.NEW
        };
    }

    getLastUserIndex(users) {

        if (users == null || users.length === 0)
            return 1;
        const lastAddedUser = users[users.length - 1];
        return lastAddedUser.index + 1;
    }

    deleteDelegateStaffUser(delegateStaffUser) {
        const updatedDelegateStaffUsers = this.getUpdatedDelegateStaff(delegateStaffUser);
        const errors = this.getUpdatedErrors(delegateStaffUser);
        //this.setState({ delegateStaff: updatedDelegateStaffUsers, errors });
        this.props.formData.handleDelegateStaffChange(updatedDelegateStaffUsers);
        this.props.formData.handleDelegateStaffErrorsChange(errors);
    }

    getUpdatedDelegateStaff(delegateStaffUser) {
        const deletedUserIndex = delegateStaffUser.index;
        if (delegateStaffUser.status === RecordStatus.EDITABLE) {
            const delegateStaff = this.state.delegateStaff;
            const delegateStaffToDelete = delegateStaff.find(user => user.index === deletedUserIndex && user.status === RecordStatus.EDITABLE);
            delegateStaffToDelete.status = RecordStatus.DELETED;
            return delegateStaff;
        }
        else if (delegateStaffUser.status === RecordStatus.NEW) {
            const updatedDelegateStaff = this.state.delegateStaff.filter(user => !(user.index === deletedUserIndex && user.status === RecordStatus.NEW));
            this.updateIndexes(updatedDelegateStaff, deletedUserIndex);
            return updatedDelegateStaff;
        }
        else {
            toast.error("Error deleting user.");
            return [];
        }
    }

    getUpdatedErrors(delegateStaffUser) {
        const errors = this.state.errors;
        if (delegateStaffUser.status === RecordStatus.DELETED)
            return errors;
        const deletedUserIndex = delegateStaffUser.index;
        const updatedErrors = errors.filter(error => error.index !== deletedUserIndex);
        this.updateIndexes(updatedErrors, deletedUserIndex);
        return updatedErrors;
    }

    updateIndexes(items, deletedIndex) {
        items.forEach(item => {
            if (item.index > deletedIndex)
                item.index--;
        });
    }

    inputHandler = (e, user) => {
        const name = e.target.name;
        const delegateStaff = this.state.delegateStaff;
        const delegateStaffUser = delegateStaff.find(u => u.index === user.index && u.status === user.status);
        const value = e.target.value;

        if (name.includes("delegate-user-first-name"))
            delegateStaffUser.firstName = value;
        else if (name.includes("delegate-user-last-name"))
            delegateStaffUser.lastName = value;
        else if (name.includes("delegate-user-email"))
            delegateStaffUser.email = value;

        //this.setState({ delegateStaff: delegateStaff });
        this.props.formData.handleDelegateStaffChange(delegateStaff);
    }

    close() {
        //const initialState = this.createInitialState();
        //this.setState(initialState);
        this.props.formData.handleCloseStaff();
    }

    async save() {
        //this.setState({ loading: true });
        this.props.formData.handleDelegateStaffLoadingChange(true);
        const errors = this.validateDelegateStaff();
        //this.setState({ errors: errors });
        this.props.formData.handleDelegateStaffErrorsChange(errors);
        if (errors.length > 0) {
            this.props.formData.handleDelegateStaffLoadingChange(false);
            return;
        }

        const dto = this.getDelegateStaffDto();
        await solicitationService
            .saveSolicitationDelegateStaff(dto)
            .then(result => {
                if (result.status === HTTPStatusCodes.SUCCESS) {
                    toast.success("Delegate Staff Updated Successfully");
                    this.props.formData.handleOpenStaff(dto.solicitationId, dto.taskAreaContractId, true);
                    this.props.formData.handleOpenStaff(dto.solicitationId, dto.taskAreaContractId, false);
                    //this.setState({ loading: false });
                    this.props.formData.handleDelegateStaffLoadingChange(false);
                }

            })
            .catch(ex => {
                //console.log(ex);
                //this.setState({ loading: false });
                

                if (ex.response.status === 400) {
                    toast.error(ex.response.data);
                } else {
                    toast.error("Error updating Delegate Staff.");
                }
                this.props.formData.handleDelegateStaffLoadingChange(false);
                this.props.formData.handleOpenStaff(dto.solicitationId, dto.taskAreaContractId, false);
            });
    }

    validateDelegateStaff() {
        const delegateStaff = this.state.delegateStaff;
        const schema = Joi
            .array()
            .items(Joi
                .object()
                .keys({
                    firstName: Joi.string().required().error(() => { return { message: "First name is required." }; }),
                    lastName: Joi.string().required().error(() => { return { message: "Last name is required." }; }),
                    email: Joi.string().email().required().error(() => { return { message: "Valid email is required." }; })
                }));

        const toValidate = [];
        delegateStaff.forEach(user => toValidate.push({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
        }));

        const errors = [];
        const { error } = Joi.validate(toValidate, schema, { abortEarly: false });
        if (error) {
            for (let detail of error.details) {
                const errObj = new Object();
                [errObj.index, errObj.fieldName] = detail.path;
                errObj.message = detail.message;
                errors.push(errObj);
            }
        }
        this.checkDuplicateEmails(toValidate, errors);
        return errors;
    }

    checkDuplicateEmails(toValidate, errors) {
        toValidate.forEach((user, index) => {
            const email = user.email.toLowerCase();
            const duplicateEmailUsers = toValidate.filter(u => u.email.toLowerCase() === email);
            if (duplicateEmailUsers && duplicateEmailUsers.length > 1) {
                const errObj = new Object();
                errObj.index = index;
                errObj.fieldName = "email";
                errObj.message = "Duplicate emails are not allowed.";
                errors.push(errObj);
            }
        });
    }

    getDelegateStaffDto() {
        const delegateStaff = this.state.delegateStaff;
        const formData = this.props.formData;
        const parentState = formData.state;
        const selectedSolicitationId = parentState.selectedSolicitationId;
        const selectedTaskAreaContractId = parentState.selectedTaskAreaContractID;
        const delegateStaffToSave = delegateStaff.filter(ds => ds.status !== RecordStatus.READ_ONLY);
        return {
            solicitationId: selectedSolicitationId,
            taskAreaContractId: selectedTaskAreaContractId,
            delegateStaff: delegateStaffToSave
        };
    }

    getErrorMessage(errors, index, propertyName) {
        if (!errors || errors.length === 0)
            return null;

        const error = errors.find(e => e.index === index && e.fieldName === propertyName);
        if (!error)
            return null;

        return error.message;
    }

    renderDelegateStaffUserRow(delegateStaffUser) {
        if (delegateStaffUser.status === RecordStatus.DELETED)
            return <React.Fragment key={delegateStaffUser.index} />;

        const isDisabled = delegateStaffUser.status === RecordStatus.READ_ONLY;
        //const isDisabled = delegateStaffUser.status !== RecordStatus.NEW;
        const isEmailDisabled = delegateStaffUser.status !== RecordStatus.NEW;
        const index = delegateStaffUser.index;
        return <tr style={{ height: 60 }} key={index}>
            {this.renderDelegateStaffUserCell(index, "first-name", isDisabled, "First Name", "firstName", delegateStaffUser)}
            {this.renderDelegateStaffUserCell(index, "last-name", isDisabled, "Last Name", "lastName", delegateStaffUser)}
            {this.renderDelegateStaffUserCell(index, "role-name", true, "Role Name", "roleName", delegateStaffUser)}
            {this.renderDelegateStaffUserCell(index, "email", isEmailDisabled, "E-Mail", "email", delegateStaffUser)}
            {this.renderDeleteStaffUserCell(delegateStaffUser)}
        </tr>;
    }

    renderDelegateStaffUserCell(index, labelPrefix, isDisabled, placeholderText, propertyName, delegateStaffUser) {
        const value = delegateStaffUser[propertyName];
        const error = this.getErrorMessage(this.state.errors, index, propertyName);
        return <td>
            <Input
                name={`delegate-user-${labelPrefix}-${index}`}
                type="text"
                value={value}
                isDisabled={isDisabled}
                placeholder={placeholderText}
                error={error}
                onChange={(e) => this.inputHandler(e, delegateStaffUser)} />
        </td>;
    }

    renderDeleteStaffUserCell(delegateStaffUser) {
        const status = delegateStaffUser.status;
        if (status === RecordStatus.READ_ONLY || status === RecordStatus.DELETED)
            return <td></td>;

        return <td>
            <button
                value="Delete"
                type="button"
                onClick={() => this.deleteDelegateStaffUser(delegateStaffUser)}
                className="right-align-btn btn-white MuiButton-root MuiButtonBase-root btn btn-outline-primary">
                Delete
            </button>
        </td>;
    }

    renderDelegateStaffHeaderCell(header) {
        return <th className={`MuiTableCell-root MuiTableCell-head MuiTableCell-alignRight`} scope="col">{header}</th>;
    }

    render() {
        const formData = this.props.formData;
        const parentState = formData.state;
        const delegateStaffUsers = this.state.delegateStaff;

        const loader = (<div className="loader">Loading...</div>);

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="delegate-staff"
            aria-describedby="delegate-new-staff"
            open={parentState.delegateStaffModal.open === 'delegateStaff'}
            onClose={this.close}
            fullWidth={true}
            maxWidth="lg">
            <DialogTitle className={`vendor-modal-title`}>
                <span className="modal-title" id="add-user-modal-label">Delegate Staff for {this.state.vendorOrgName}</span>
                <button type="button" className="close" onClick={this.close} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </DialogTitle>
            <DialogContent className="dialog-no-padding-top">

                {(parentState.loading || this.state.loading) && loader}

                <div className={`modal-body`} id="delegate-staff">
                    <form id="delegate-staff-form" className={`vendor-modal-table existing-user white-bordered-rows`}>
                        <table className={`MuiTable-root user-contact`}>
                            <thead className={`MuiTableHead-root headless-table123`}>
                                <tr className={`MuiTableRow-root MuiTableRow-head`}>
                                    {this.renderDelegateStaffHeaderCell("Name")}
                                    {this.renderDelegateStaffHeaderCell("")}
                                    {this.renderDelegateStaffHeaderCell("Role")}
                                    {this.renderDelegateStaffHeaderCell("Email")}
                                    {this.renderDelegateStaffHeaderCell("Actions")}
                                </tr>
                            </thead>
                            <tbody className={`MuiTableBody-root`}>
                                {this.state.delegateStaff && this.state.delegateStaff.map(delegateStaffUser => this.renderDelegateStaffUserRow(delegateStaffUser))}
                                <tr>
                                    <td colSpan="5">
                                        <div className="vendor-table-actions">
                                            <button
                                                onClick={this.addNewDelegateStaff}
                                                value="AddNew"
                                                type="button"
                                                className="new-vendor-btn MuiButton-root MuiButtonBase-root btn btn-contained">
                                                Add New Delegate Staff
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <div className="dialog-footer">
                    <Button type="button" className="MuiButtonBase-root MuiButton-root btn btn-secondary" onClick={this.close}>
                        Close
                    </Button>
                    <Button
                        type="button"
                        onClick={this.save}
                        className="MuiButtonBase-root MuiButton-root btn btn-primary">
                        Save
                    </Button>
                </div>
            </DialogActions>
        </Dialog>;
    }
}

export default DelegateStaffModal;