import React, { Component } from "react";
import auth from "../services/authService";
import ErrorRedirect from "./common/ErrorRedirect";
import UrlService from "../services/urlService"
import ecpsExtTokenCreation_Key from "../config.json";

class OtpLogin extends Component {
    state = {
           authenticated: 0,
           errors: {}
    };

    componentDidMount() {
        const hash = UrlService.getHashFromUrl(this.props.location.hash);
        //console.log(hash);
        const body = { accTk: hash.access_token, idToken: hash.id_token, reserved: this.props.match.params.str };

        auth.login(body)
            .then((response) => {
                //console.log(response);
                let newDate = new Date();
                let date = newDate.getTime();
                //console.log("creation date " + date);
                localStorage.setItem(ecpsExtTokenCreation_Key, JSON.stringify(date));
                this.setState({ authenticated: 1 });
            })
            .catch((ex) => {
                if (ex.response && ex.response.status) {
                    this.setState({ status: ex.response.status });
                }
                this.setState({ authenticated: -1 });
            })
    }

 
    render() {

        if (this.state.authenticated === 1) {
            setTimeout(() => window.location = "/", 1000);
            return (<></>);
        }            
        else if (this.state.authenticated === 0)
            return (
                <React.Fragment>
                    <div className="content" style={{ padding: "10px", backgroundColor: "#fff", width: "100%" }}> Authenticating ... </div>
                </React.Fragment>
            )
        else //-1
            ErrorRedirect(this.state.status);
    }
}

export default OtpLogin;
