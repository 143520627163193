import React from "react";
import JwtDecode from "jwt-decode";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import https from "./httpService";
import {
    ecpsExternalAccessToken_Key,
    ecpsExternalRefreshToken_Key,
    ecpsExtTokenCreation_Key,
    ecpsExtRefreshInProgress_Key,
    ecpsExternalIdToken_Key,
    ecpsExternalIsAccessTokenRefreshed_Key,
    ecpsExternalCurrentUser_Key
} from "../config.json";

export async function login(body) {
    return (
    https.post("/otp/callback", body)
        .then((response) => {
            const { otpIdToken, otpUser } = response.data;
            var otpUserJson = JSON.stringify(otpUser);
            localStorage.setItem(ecpsExternalIdToken_Key, otpIdToken);
            localStorage.setItem(ecpsExternalCurrentUser_Key, otpUserJson);

            let newDate = new Date();
            let date = newDate.getTime();
            localStorage.setItem(ecpsExtTokenCreation_Key, JSON.stringify(date));

            return response;
        })
    );
 }

export async function refreshToken() {
    try {
   
        const response = await https.post("/otp/refresh"); 
 
        const { otpIdToken, otpUser } = response.data;
        var otpUserJson = JSON.stringify(otpUser);
        localStorage.setItem(ecpsExternalIdToken_Key, otpIdToken);
        localStorage.setItem(ecpsExternalCurrentUser_Key, otpUserJson);

        localStorage.removeItem(ecpsExtTokenCreation_Key);

        localStorage.setItem(ecpsExternalIsAccessTokenRefreshed_Key, "true");
      
        let newDate = new Date();
        let date = newDate.getTime();
        localStorage.setItem(ecpsExtTokenCreation_Key, JSON.stringify(date));
        localStorage.removeItem(ecpsExtRefreshInProgress_Key);
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
      if (isTokenRefreshed()) {
          localStorage.removeItem(ecpsExternalIsAccessTokenRefreshed_Key);
      }

      if (localStorage.getItem(ecpsExtRefreshInProgress_Key)) {
          localStorage.removeItem(ecpsExtRefreshInProgress_Key);
      }
    }
  }
}

export async function logout() {
    const idToken = localStorage.getItem(ecpsExternalIdToken_Key);

    localStorage.removeItem(ecpsExternalIdToken_Key);
    localStorage.removeItem(ecpsExtTokenCreation_Key);
    if (isTokenRefreshed()) {
        localStorage.removeItem(ecpsExternalIsAccessTokenRefreshed_Key);
    }
    localStorage.removeItem(ecpsExternalCurrentUser_Key);
    if (localStorage.getItem(ecpsExtRefreshInProgress_Key)) {
        localStorage.removeItem(ecpsExtRefreshInProgress_Key);
    }
    return await https.post("/logoutOtp", null, { params: { idToken } } );
}

export function getCurrentUser() {
    try {
        var userJson = localStorage.getItem(ecpsExternalCurrentUser_Key);
        if (userJson) {
            return JSON.parse(userJson);
        }
        return null;
    } catch (ex) {
        return null;
    }
}

export function getUserRoles() {
    var icRoles = [];
    try {
        const user = getCurrentUser();
        Object.keys(user).forEach(function (key) {
            let res = key.split("/");
            if (res.length > 1) {
                if (res[res.length - 1] === 'role') {
                    var len = user[key].length;
                    for (var i = 0; i < len; i++) {
                        var t = JSON.parse(user[key][i]);
                        icRoles.push(t);                    
                    }
                }
            }
        });
    } catch (ex) {
        
    }

    if (icRoles.length === 0) {
        icRoles = JSON.parse('[{"icid": "", "role": ""}]');
    }

    return icRoles;
}

/* export function getJwt() {
  return localStorage.getItem("accessToken");
} */

export function isTokenRefreshed() {
    return localStorage.getItem(ecpsExternalIsAccessTokenRefreshed_Key);
}

export default {
    login,
    logout,
    getCurrentUser,
    getUserRoles,
    refreshToken,
    isTokenRefreshed,
};
