import React, { Component } from 'react';
import Footer from "../Footer";

const errorStyle = {
    backgroundColor: "#e3e3e3",
    margin: "20px",
    padding: "20px",
    borderRadius: "10px"
    
}

class Error404 extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content">
                        <div className="tile-section contact-section">
                            <h2>404 Page Not Found</h2>

                            <p>Page does not exit.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    };
};

export default Error404;
