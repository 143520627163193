import React, { Component } from 'react';
import Footer from "../Footer";
import config from "../../config.json";

class AwafError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*supportId: this.props.match.params.id?.toString()*/
            supportId: localStorage.getItem(config.awafTk)
        };
    }

    componentDidMount() {
        //localStorage.removeItem(config.awafTk);
    }

    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content">
                        <div className="tile-section contact-section">
                            <h2> Request Rejected </h2>
                            {this.state.supportId && (
                                <div>
                                    <p>
                                        The requested URL was rejected. Please contact
                                        <a href="mailto:NIAIDeCPSeRDSSupport@niaid.nih.gov"> NIAIDeCPSeRDSSupport@niaid.nih.gov </a>
                                        with the Support ID information.
                                    </p>

                                    <p>
                                        Your support ID is: {this.state.supportId}
                                    </p>
                                </div>
                                )
                            }

                            {!this.state.supportId && (
                                <div>
                                    <p>
                                        The requested URL was rejected. Please contact
                                        <a href="mailto:NIAIDeCPSeRDSSupport@niaid.nih.gov"> NIAIDeCPSeRDSSupport@niaid.nih.gov. </a>
                                    </p>
                                </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
);
}
};

export default AwafError;