import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#2d5670',
        color: '#fff',
        maxWidth: 300,
        fontSize: '16px',
        border: '1px solid #2d5670',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    arrow: {
        color: '#2d5670',
    }
}))(Tooltip);

export class DataColumn extends Component {
    valueChecker = (data) => {
        return <div className="column-data"><span className="filler-text">No data found</span></div>;
    }

    dataDecider = (e) => {
        switch (e.type) {
            case 'dateTime':
                if (!e.date) {
                    return this.valueChecker(this.props)
                } else {
                    return <div className="column-data">{e.date} <small>{e.time}</small></div>;
                }
                break;
            case 'plainText':
                if (!e.text) {
                    return this.valueChecker(this.props)
                } else {
                    return <div className="column-data">{e.text}</div>
                }
                break;
            case 'reviewBoolean':
                if (!e.reviewStatus) {
                    return <div className="column-data"><img src="images/close-cross-in-circular-outlined-interface-button.svg" alt="review-complete-icon" />NO</div>
                } else {
                    return <HtmlTooltip
                        title={
                            <React.Fragment>
                                <span>{e.reviewName}</span>
                                <span className="tooltipDate">{e.date} {e.time}</span>
                            </React.Fragment>
                        }
                        arrow
                    >
                        <div className="column-data">
                            <img src="images/accept-circular-button-outline.svg" alt="review-complete-icon" />YES
          </div>
                    </HtmlTooltip>
                }
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="column-block">
                <div className="column-label">{this.props.label}</div>
                {this.dataDecider(this.props)}
            </div>
        );
    }
}

export default DataColumn;