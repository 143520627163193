import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class HowToSubmit extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">How To Submit an Electronic Proposal</div>
                            <Paper className="content-container">
                            <ol>
                                    <li>Log in to the NIH eCPS (electronic Contract Proposal Submission system).</li>
                                    <li>Click <strong>Submit a Proposal</strong> for the Solicitation for which you wish to submit a proposal from “Contract Solicitations Available for Electronic Submission” page.</li>
                                <li>Enter a name for the proposal.</li>
                                <li>Browse and select the Technical PDF, PHS Human Subjects and Clinical Trials PDF, Business PDF, and Excel file(s).
                                    <ol type="a">
                                            <li>The Technical Proposal, including all appendices, must be saved in a single PDF file. The Technical PDF file must enable word searches to the maximum extent practicable. Forms and/or documents requiring signature(s) may be scanned, but must be merged into the single Technical PDF file.</li>
                                            <li>PHS Human Subjects and Clinical Trials Document - Upload the Human Subjects and Clinical Trials Information Form as required by the solicitation.<br />Please ensure that you are using correct version of the <a href="https://oamp.od.nih.gov/nih-document-generation-system/dgs-workform-information/attachment-files-section-j" className="link-textonly" target="_blank">Human Subject and Clinical Trial form</a>.</li>
                                            <li>The Business Proposal must be comprised of the following files:
                                                <ol type="I">
                                                    <li><strong>Required:</strong> A single PDF of your Business Proposal, including all attachments and/or appendices as required by the solicitation. The Business Proposal should be created in a PDF format that enables word searches to the maximum extent practicable. Forms and/or documents requiring signature(s) may be scanned, but must be merged into the single Business Proposal PDF file. Do NOT password-protect or encrypt the PDF file.</li>
                                                    <li><strong>Optional:</strong> Cost breakdown(s) as required by the solicitation, in its original Excel format, not the PDF format. The cost breakdown(s) in excel must be identical to the one(s) in the Business Proposal PDF.</li>
                                                </ol>
                                            </li>
                                    </ol>
                                </li>
                                
                                <li>Click <strong>Submit Proposal.</strong></li>
                                <li>Once you upload and submit your proposal, you can only revise your proposal by overwriting previously uploaded documents. You can find submissions to revise or view under <strong>My Submission History</strong>. You have up until the closing date and time of the solicitation to make as many revisions as you need.</li>
                                <li>After the closing date and time, the system prevents further proposal submissions or revisions. You may still view your submissions after the proposal closing date and time under <strong>My Submission History</strong>.</li>
                                </ol>
                            </Paper>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
};

export default HowToSubmit;