import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import AppAccessDenied from "./components/Errors/AppAccessDenied";
import AwafError from "./components/Errors/AwafError";
import Error from "./components/Errors/Error";
import DeadlineError from "./components/Errors/DeadlineError";
import Error404 from "./components/Errors/Error404";
import AuthenticationFail from "./components/Errors/AuthenticationFail";
import About from "./components/About";
import HowToSubmit from "./components/HowToSubmit";
import ContactUs from "./components/ContactUs";
import Faq from "./components/Faq";
import AccountLocked from "./components/Errors/AccountLocked";
import { Layout } from "./components/Layout";
import { LayoutSecure } from "./components/secure/LayoutSecure";
import { Home } from "./components/Home";
import { FetchData } from "./components/FetchData";
import { SubmitProposal } from "./components/secure/SubmitProposal";
import { SubmitSpecialProposal } from "./components/secure/SubmitSpecialProposal";
import { ReviseProposal } from "./components/secure/ReviseProposal";
import { Counter } from "./components/Counter";
import LoginForm from "./components/LoginForm";
import Logout from "./components/Logout";
import auth from "./services/authService";
//import RefreshToken from "./components/RefreshToken";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/common/protectedRoute";
import { MySolicitations } from "./components/secure/MySolicitations";
import { Solicitation } from "./components/secure/Solicitation";
import { StaffManagement } from "./components/secure/StaffManagement";
import "react-toastify/dist/ReactToastify.css";
import IcRoleService from "./services/icRoleService";
import "./custom.css";

import OtpLogin from "./components/OtpLogin"
import SubmissionHistory from "./components/secure/SubmissionHistory";
import { datadogRum } from '@datadog/browser-rum';

import analytics from "./services/analytics";

var domain = window.location.host.split(".")[0];
var env = 'local';
var rumStart = false;
switch (domain) {
    case 'ecps-ext-dev':
        env = 'dev';
        break;
    case 'ecps-ext-stg':
        env = 'stage';
        break;
    case 'ecps-qa':
        env = 'qa';
        rumStart = true;
        break;
    case 'ecps':
        env = 'production';
        rumStart = true;
        break;
    default:
        env = 'local';
        break;
}

if (rumStart) {
    datadogRum.startSessionReplayRecording();
}


export default class App extends Component {
    static displayName = App.name;
    state = {};

    async componentDidMount() {

        if (rumStart) {
            datadogRum.init({
                    applicationId: '6e92494d-63d1-445f-aa17-8e962bfedcaa',
                    clientToken: 'pub8434878429539a8172bc1559b2bd7e92',
                    site: 'ddog-gov.com',
                    service: 'eCPS.External.WebUI',
                    project: 'eCPS',
                    env: env,
                    sampleRate: 100,
                    premiumSampleRate: 100,
                    trackInteractions: true,
                    defaultPrivacyLevel: 'allow',

                    beforeSend: (event, context) => {
                        if (event.type === 'resource' && event.resource.type === 'xhr') {
                            event.context = {
                                ...event.context,
                                responseHeaders: context.response?.headers ?? "",
                                xhrresponse: context.xhr.response
                            };
                        }
                    }
                }
            );

            datadogRum.addRumGlobalContext(
                "domain",
                domain
            );
        }

        const user = auth.getCurrentUser();
        if (user) {
            datadogRum.setUser({
                id: user.appId,
                name: user.firstName + " " + user.lastName,
                email: user.email,
                plan: 'premium'
            });
        }

        analytics.initAnalytics();

        this.setState({ user: user });
    }


    routes = function (user) {
        return (
            <React.Fragment>
                <ToastContainer position="top-center" autoClose={5000} />

                <Layout /*user={user} icRoleSelector={this.icRoleSelector} defaultIcRole={this.state.selectedIcRole}*/ >
                    <Switch>
                        {/*<Route path="/counter" component={Counter} />*/}
                        {/*<ProtectedRoute path="/fetch-data" component={FetchData} />*/}
                        {/*<ProtectedRoute path="/solicitation/:id" component={Solicitation} />*/}
                        <ProtectedRoute path="/submitProposal/:id" component={SubmitProposal} />
                        <ProtectedRoute path="/submitSpecialProposal/:id/:specialInviteId" component={SubmitSpecialProposal} />
                        <ProtectedRoute path="/reviseProposal/:id" component={ReviseProposal} />
                        <ProtectedRoute path="/MySolicitations" component={MySolicitations} />
                        <ProtectedRoute path="/submissionHistory" component={SubmissionHistory} />
                        {/*<Route path="/refreshToken" component={RefreshToken} /> */}
                        <Route path="/home" exact component={Home} />
                        <Route path="/" exact component={Home} />
                        <Route path="/About" component={About} />
                        <Route path="/HowToSubmit" component={HowToSubmit} />
                        <Route path="/contactUs" component={ContactUs} />
                        <Route path="/Faq" component={Faq} />
                        
                        {/*<Route path="/not-found" component={NotFound}></Route>*/}
                        <Route path="/otpLogin/:str" component={OtpLogin}></Route>
                        <Route path="/otpLogin" component={OtpLogin}></Route>
                        <Route path="/logout" component={Logout} />
                        <Route path="/accountLocked" component={AccountLocked}></Route>
                        <Route path="/appAccessDenied" component={AppAccessDenied}></Route>
                        <Route path="/error" component={Error}></Route>
                        <Route path="/deadlineerror" component={DeadlineError}></Route>
                        <Route path="/authenticationFail" component={AuthenticationFail}></Route>
                        <Route path="/AwafError/:id?" component={AwafError}></Route>
                        <Route component={Error404}></Route>
                    </Switch>
                </Layout>
            </React.Fragment>
        )
    }

   render() {
      const { user } = this.state;
      //console.log(user);
      return (this.routes(user));
   }
}

