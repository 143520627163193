import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class About extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">Electronic Contract Proposal Submission</div>
                            <Paper className="content-container">
                                <React.Fragment>
                                    <p>NIH has implemented a new process for electronic submission of contract proposals. The electronic Contract Proposal Submission (eCPS) is one component of NIH’s integrated, secure system for the electronic submission, capture, tracking and review of contract proposals. eCPS was developed for the purposes of streamlining our contract proposal submission and review processes, preparing for the eventual elimination of paper submissions, and reducing costs associated with storage of large paper files.</p>

                                    <div className="sub-header">Features:</div>
                                    <ul>
                                        <li>Upload files for electronic submission against a list of active contract solicitations</li>
                                        <li>Link to solicitations in sam.gov</li>
                                        <li>View solicitation titles and submission deadlines</li>
                                        <li>Track proposal submission actions through "My Submission History"</li>
                                        <li>Revise a proposal, which will over-write previous versions</li>
                                    </ul>
                                    <div className="sub-header">Benefits to the research community:</div>
                                    <ul>
                                        <li>Easy-to-locate active solicitations available for electronic submission</li>
                                        <li>Reduced costs for printing and mailing of paper files</li>
                                        <li>Eliminate security risk of paper processing and transportation time</li>
                                        <li>Real-time feedback on transmission success</li>
                                    </ul>
                                    <div className="sub-header">Benefits to the Office of Acquisitions:</div>
                                    <ul>
                                        <li>Single source for all proposal submissions</li>
                                        <li>Reduced costs for storing large paper files</li>
                                        <li>Enhanced security with electronic files</li>
                                        <li>Reduced processing and review time</li>
                                    </ul>
                                </React.Fragment>
                            </Paper>
                        </div>
                    </div >
                </div>
                <Footer />
            </div>
        )
    };
};

export default About;