import React, { Component } from "react";

import SolicitationType from "../../enums/SolicitationType";

export class SolicitationFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: null };

        // this.handleClickInvited = this.handleClick.bind(this);
    }

    handleClickInvited(isBaseSolicitation) {
        let isToggleOnResult = this.state.isToggleOn === isBaseSolicitation ? null : isBaseSolicitation;
        this.setState({ isToggleOn: isToggleOnResult });
        this.props.filterClick(isBaseSolicitation);
    }



  render() {

    const isToggleOn = this.state.isToggleOn;
    var content = (
        <div className="solicitation-actions">
            {this.props.solList.filter(key => key.solicitationTypeId === SolicitationType.RTOP || key.solicitationTypeId === SolicitationType.Other || key.solicitationTypeId === SolicitationType.ARPA).length > 0 && <div className="solicitation-filter-btns">
                <button
                    onClick={() => this.handleClickInvited(false)}
                    className={`btn ${isToggleOn === false ? 'active' : ''}`}>
                    <span>{this.props.solList.filter(key => key.solicitationTypeId === SolicitationType.RTOP || key.solicitationTypeId === SolicitationType.Other || key.solicitationTypeId === SolicitationType.ARPA).length}</span>
                    <span>Invited Solicitations</span>
                </button>
                <button
                    onClick={() => this.handleClickInvited(true)}
                    className={`btn ${isToggleOn === true ? 'active' : ''}`}>
                    <span>{this.props.solList.filter(key => key.solicitationTypeId === SolicitationType.Base).length}</span>
                    <span>Open Solicitations</span>
                </button>
            </div>}
            {this.props.solList.filter(key => key.solicitationTypeId === SolicitationType.RTOP).length > 0 && 
            <span>Select to filter solicitations below</span>}
        </div>
    );

    return (
      /*this.props.solPageModel ?*/ content /*: ''*/
    );
  }
}

export default SolicitationFilter;