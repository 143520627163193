import React from "react";
import { Route, Redirect } from "react-router";

import auth from "../../services/authService";
const ProtectedRoute = ({ path, component: Component, render }) => {

  return (
    <Route
        path={path}
        render={(props) => {
            if (!auth.getCurrentUser()) {
                auth.logout()
                    .then(res => {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: props.location },
                                }}
                            />
                        );
                    })
                    .catch(err => {
                        window.location = "/logout";
                    })
                
            }
          
            return Component ? <Component {...props} /> : render(props);
          
            //return render(props);
        }}
    />
  );
};

export default ProtectedRoute;
