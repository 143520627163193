import React, { Component } from "react";
import "./LoginForm.css";

class LoginForm extends Component {
  
  render() {
    //const { data, errors } = this.state;
    //if (auth.getCurrentUser()) return <Redirect to="/" />;
    return (
        <div className="jumbotron-lg-content">
            <div className="jumbotron-text">
                <div className="primary-jumbo-text">Welcome to the electronic Contract Proposal Submission (eCPS)</div>
                <div className="secondary-jumbo-text">The electronic Contract Proposal Submission (eCPS) is a component of an integrated, secure system for the electronic submission, capture, tracking and review of contract proposals.</div>
            </div>
            <div className="jumbotron-action">
                <div className="guide-text">Ready to submit a proposal?</div>
                <button className="MuiButtonBase-root MuiButton-root btn-guide" tabIndex="0" type="button" onClick={() => { window.location = '/siteminder/renderSiteMinder'; }}>
                    <span className="MuiButton-label">
                        eCPS Login
                    </span>
                    <span className="MuiTouchRipple-root"></span>
                    <div className="chevron-right"><img src="images/banner-arrow.svg" alt="site-login" /></div>
                </button>
                <a href="#" className="register-account">Register new account<img src="images/icon-arrow-right-blue-light.svg" alt="register-new-account"/></a>
            </div>
        </div>
    );
  }
}

export default LoginForm;
