import React, { Component, Fragment } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ShowSidebar } from "./constants";
import solicitationService from "../../services/solicitationService";
// import '../siteStyles.css';

function addYears(dt, n) {
    return new Date(dt.setFullYear(dt.getFullYear() + n));
}
function addMonths(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
}
export class sidebar extends Component {

    constructor(props) {
        super(props);

        var defaultFields = this.getDefaultSearchFields();
        this.state = {
            keyword: defaultFields.keyword,
            closingDateStart: defaultFields.closingDateStart,
            originalclosingDateStart: defaultFields.closingDateStart,
            closingDateEnd: defaultFields.closingDateEnd,
            institute: defaultFields.institute,
            agency: defaultFields.agency,
            sortBy: defaultFields.sortBy,
            institutes: {
                allIcsDdList: {}
            }
        };
    }

    getMinDate() {
        return this.getEstDate(new Date());
    }

    getDefaultSearchFields() {
        var initStartDate = this.getEstDate(new Date());

        const defaultSearchFieldValues = {
            keyword: "",
            closingDateStart: initStartDate,      // new Date().toLocaleString(),
            originalclosingDateStart: initStartDate,
            closingDateEnd: addYears(new Date(initStartDate), 1),
            institute: "All",
            agency: "All",
            sortBy: "ClosingDate"
        };
        return defaultSearchFieldValues;
    }

    async componentDidMount() {
        const isSidebarShown = !!localStorage.getItem(ShowSidebar);
        this.setState({ ShowSidebar: isSidebarShown !== ShowSidebar });
        this.props.setDefaultSearchInfo(this.getDefaultSearchFields());
        this.fetchInstituteList();
    }

    searchInfo = (e) => {
        var searchInfo = {
            keyword: this.state.keyword,
            closingDateStart: this.state.closingDateStart,
            originalclosingDateStart: this.state.originalclosingDateStart,
            closingDateEnd: this.state.closingDateEnd,
            institute: this.state.institute,
            agency: this.state.agency
        };
        return searchInfo;
    }
    resetSearchFields(e) {
        var defaultFields = this.getDefaultSearchFields();
        this.setState({
            keyword: defaultFields.keyword,
            closingDateStart: defaultFields.closingDateStart,
            originalclosingDateStart: defaultFields.closingDateStart,
            closingDateEnd: defaultFields.closingDateEnd,
            institute: defaultFields.institute,
            agency: defaultFields.agency,
            sortBy: defaultFields.sortBy
        });
        this.props.onSortBy(defaultFields.sortBy, this.stateChangeComplete);
    }
    getInstitutesCorrespondingToAgency() {
        switch (this.state.agency.toLowerCase()) {
            case 'all':
                return this.state.institutes.allIcsDdList;
            case 'nih/arpa-h/cdc/samhsa':
                return this.state.institutes.nihIcsDdList;
            case 'sbir/sttr':
                return this.state.institutes.sbirIcsDdList;
            default:
                return this.state.institutes.allIcsDdList;

        }
    }
    stateChangeComplete = () => {
        var defaultFields = this.getDefaultSearchFields();
        this.props.search(defaultFields);
    }
    async fetchInstituteList() {
        const { data } = await solicitationService.getInstituteList();
        this.setState({ institutes: data });
    }

    getEDTBeginDate(dt) {
        var dayLightBegin = null;
        var days = new Date(dt.getFullYear(), 2, 0).getDate();
        for (var i = 1; i <= days; i++) {
            var testDt = new Date(dt.getFullYear(), 2, i, 2);
            var month = testDt.getMonth() + 1;
            var day = testDt.getDay();
            var year = testDt.getFullYear();
            var dates = testDt.getDate();
            if (month === 3 && day === 0 && dates >= 8 && dates <= 15) {
                dayLightBegin = testDt;
                return dayLightBegin;
            }
        }
        return null;
    }

    getEDTEndDate(dt) {
        var dayLightBegin = null;
        var days = new Date(dt.getFullYear(), 10, 0).getDate();
        for (var i = 1; i <= days; i++) {
            var testDt = new Date(dt.getFullYear(), 10, i, 2);
            var month = testDt.getMonth() + 1;
            var day = testDt.getDay();
            var year = testDt.getFullYear();
            var dates = testDt.getDate();
            if (month === 11 && day === 0 && dates >= 1 && dates <= 7) {
                dayLightBegin = testDt;
                return dayLightBegin;
            }
        }
        return null;
    }

    getEstDate = (date) => {
        var dt = date && date.getUTCHours() !== 0
            ? ((theDate) =>
                new Date(theDate.getTime() + theDate.getTimezoneOffset() * 60 * 1000 - 300 * 60 * 1000))(date)
            : date;

        if (dt <= this.getEDTEndDate(dt) && dt >= this.getEDTBeginDate(dt)) {
            dt = new Date(dt.getTime() + 60 * 60 * 1000);
        }
        
        return dt.toDateString();
    }

    render() {
        const toggleSidebar = () => {
            this.setState({ ShowSidebar: this.state.ShowSidebar !== ShowSidebar });
            localStorage.setItem(ShowSidebar, this.state.ShowSidebar);

        };

        const cToAgent = this.getInstitutesCorrespondingToAgency();
        return (
            <div className={`sidebar float-sidebar ${this.state.ShowSidebar !== ShowSidebar ? 'closed' : ''}`}>
                <button className="btn sidebar-trigger float-trigger" onClick={() => { toggleSidebar() }}>
                    Filters
                    <img src="./images/icon-arrow-down-light.svg" alt="toggle arrow" style={{ position: "relative", zIndex: "-100" }} />
                </button>
                <div className="sidebar-content">
                    <form>
                        <div className="MuiFormControl-root MuiTextField-root container-sortby">
                            <label
                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                data-shrink="true"
                                htmlFor="sortby"
                                id="sortby-label">
                                Sort By
                            </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <select
                                    className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input"
                                    value={this.state.sortBy}
                                    id="sortby" onChange={(e) => { this.props.onSortBy(e.target.value); this.setState({ sortBy: e.target.value }) }}>
                                    <option value="ClosingDate">Closing Date</option>
                                    <option value="Agency">Agency</option>
                                    <option value="Title">Title</option>
                                </select>
                                <img src="images/select-arrows.svg" alt="arrow dropdown" />
                            </div>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root">
                            <label
                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required"
                                data-shrink="true"
                                htmlFor="keyword"
                                id="keyword-label">
                                Keyword
                            </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <input
                                    aria-invalid="false"
                                    id="keyword"
                                    maxLength="50"
                                    type="text"
                                    className="MuiInputBase-input MuiInput-input"
                                    value={this.state.keyword}
                                    onChange={val => this.setState({ keyword: val.target.value })} />
                            </div>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root calendar-parent">
                            <Fragment>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                        <label>Closing Date Range Start</label>
                                        <KeyboardDatePicker
                                            id="closing date range start"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change closing date range start',
                                            }}
                                            value={this.state.closingDateStart}
                                            placeholder="MM/DD/YYYY"
                                            InputProps={{
                                                disableUnderline: true
                                            }}
                                            onChange={(date) => {this.setState({ closingDateStart: date });
                                            }}
                                            minDate={this.getMinDate()}
                                            format="MM/dd/yyyy"
                                            showTodayButton
                                        />
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                            </Fragment>
                            <Fragment>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Fragment>
                                        <label>Closing Date Range End</label>
                                        <KeyboardDatePicker
                                            id="closing date range end"
                                            KeyboardButtonProps={{
                                                'aria-label': 'change closing date range end',
                                            }}
                                            clearable
                                            value={this.state.closingDateEnd}
                                            placeholder="MM/DD/YYYY"
                                            InputProps={{ disableUnderline: true, }}
                                            onChange={date => this.setState({ closingDateEnd: date })}
                                            minDate={this.state.closingDateStart}
                                            format="MM/dd/yyyy" />
                                    </Fragment>
                                </MuiPickersUtilsProvider>
                            </Fragment>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root">
                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" htmlFor="agency" id="agency-label">
                                Agency/Project
                            </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="agency" value={this.state.agency} onChange={val => this.setState({ agency: val.target.value, institute: this.getDefaultSearchFields().institute })}>
                                    <option value="All" >All</option>
                                    <option value="NIH/ARPA-H/CDC/SAMHSA" >NIH/ARPA-H/CDC/SAMHSA</option>
                                    <option value="SBIR/STTR" >SBIR/STTR</option>
                                </select>
                                <img src="images/select-arrows.svg" alt="arrow dropdown" />
                            </div>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root">
                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled" data-shrink="true" htmlFor="institute" id="institute-label">
                                Institute
                        </label>
                            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                                <select className="MuiSelect-root MuiSelect-select MuiInputBase-input MuiInput-input" id="institute" value={this.state.institute} onChange={val => this.setState({ institute: val.target.value })}>
                                    <option value="All" >All</option>
                                    {
                                        
                                        Object.keys(cToAgent)?.map((key, index) => (

                                        <option key={key + index} value={cToAgent[key]}>{key}</option>
                                        ))

                                        //Object.keys(this.getInstitutesCorrespondingToAgency())?.map((key, index) => (
                                            
                                        //    <option key={key + index} value={this.state.institutes.allIcsDdList[key]}>{key}</option>
                                        //))
                                    }
                                </select>
                                <img src="images/select-arrows.svg" alt="arrow dropdown" />
                            </div>
                        </div>
                    </form>
                    <div className="sidebar-actions">
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-secondary"
                            tabIndex="0"
                            type="button"
                            onClick={() => this.resetSearchFields()}>
                            <span className="MuiButton-label">Reset</span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-primary"
                            tabIndex="0"
                            type="button"
                            onClick={() => this.props.search(this.searchInfo())}>
                            <span className="MuiButton-label">Search</span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default sidebar;
