import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export class CheckInput extends Component  {
  constructor (props) {
    super(props);
     this.state = {
        isChecked: props.isChecked
    }; 

    this.handleChange = this.handleChange.bind(this);
  }

  static propTypes = {
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isChecked: PropTypes.bool
  }

  handleChange = (e) => {
      this.setState({
        isChecked: e.target.checked
      }, () => {
          this.props.handleChange(this.props.name, this.state.isChecked);
      });
  }

  render () {
    return (
      <FormControlLabel
        className={this.props.className}
        key={this.props.name}
        control={<Checkbox checked={this.props.isChecked} onChange={this.handleChange} name={this.props.name} />}
        label={this.props.label}
      />
    );
  }
}

export default CheckInput;