import React from "react";

const Input = ({ name, label, error, type, required, isDisabled, placeholder, value, inputSize, onChange, onInput }) => {
    //  <div className={inputSize}>
    //  <label htmlFor={name}>{label}</label>
    //      <input className="MuiInput-input"
    //    name={name}
    //    id={name}
    //    type={type}
    //    defaultValue={value}
    //    onChange={onChange}
    //    className="form-control"
    //  />
    //  {error && <small className="text-danger">{error}</small>}
    //</div>
 
    //<div className={inputSize}>
    //    <div className="MuiFormControl-root MuiTextField-root">
    //        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor={name} id={`${name}-label`}>
    //          {label}
    //          {!required && <small>Optional</small>}
    //        </label>
    //        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
    //              <input id={name} type={type} name={name} className="MuiInputBase-input MuiInput-input" defaultValue={value} onChange={onChange} />
    //          </div>
    //        {error && <small className="text-danger">{error}</small>}
    //    </div>
    //</div>

    return <div className="MuiFormControl-root MuiTextField-root">
        {!required && <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor={name} id={`${name}-label`}>
            {label}
        </label>}
        {required && <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true" htmlFor={name} id={`${name}-label`}>
            {label} <span className="document-requirement"> Required</span>
        </label>}
        {isDisabled && <label>{value}</label>}
        {!isDisabled &&
            <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
                <input
                    aria-invalid="false"
                    id={name}
                    name={name}
                    type={type}
                    className="MuiInputBase-input MuiInput-input"
                    defaultValue={value}
                    //value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onInput={onInput} />
            </div>}
        {error && <small className="text-danger">{error}</small>}
    </div>;
};

export default Input;