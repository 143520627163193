import React, { Component } from "react";
import Modal from 'react-modal';
import { LayoutSecure } from "./LayoutSecure";

const customStyles = {
    position: 'relative',
    top: '10px',
    left: '200px',
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root');

export class StaffManagement extends Component {
    static displayName = LayoutSecure.name;

    constructor(props) {
        super(props);
        this.state = { showModal: false };
    }

    subtitle;

    openModal = () => this.setState({showModal: true});

    afterOpenModal = () => { this.subtitle.style.color = '#f00' };

    closeModal = () => this.setState({ showModal: false });

    render() {
        //const [modalIsOpen, setIsOpen] = React.useState(false);
        return (
            <div id="test" style={customStyles} >
                <button onClick={this.openModal}>Add User</button> 
                <Modal
                    isOpen={this.state.showModal}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h2 ref={_subtitle => (this.subtitle = _subtitle)}>Hello</h2>
                    <button onClick={this.closeModal}>close</button>
                    <div>I am a modal</div>
                    <form>
                        <input />
                        <button>tab navigation</button>
                        <button>stays</button>
                        <button>inside</button>
                        <button>the modal</button>
                    </form>
                </Modal>
            </div>
        );
    }
}