import React, { Component } from "react";
import authService from "../../services/authService";
import IdleTimer from 'react-idle-timer';
import SessionExpireModal from "./SessionExpireModal";

import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../NavMenu.css";
import { NameTag } from "../NameTag";
import config from "../../config.json";

export class NavMenuAuthenticated extends Component {
  static displayName = NavMenuAuthenticated.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        timeout: 1000 * 60 * config.ecpsExternal_UserInactiveTimeoutInMinutes, //in milliseconds 1000*60*minutes 
        timeout_modalInactive: 1000 * 60 * config.ecpsExternal_ModalInactiveTimeoutInMinutes,
        timeout_userInactive: 1000 * 60 * config.ecpsExternal_UserInactiveTimeoutInMinutes,
        showModal: false,
        userLoggedIn: true,
        isTimedOut: false,
        eventsToTrack:["mousedown","keydown","wheel"]
      };

      this.idleTimer = null;
      this.onAction = this._onAction.bind(this);
      this.onActive = this._onActive.bind(this);
      this.onIdle = this._onIdle.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleLogout = this.handleLogout.bind(this);
      this.handleRefresh = this.handleRefresh.bind(this);

    }

  
    _onAction(e) {
        //console.log("_onAction");
        //if (e) {
        //    console.log('user did something', e.type);
        //}
      
      this.setState({ isTimedOut: false });
      
      
  }

    _onActive(e) {
      //  console.log("_onActive");
      //if (e) {
      //    console.log('user is active', e.type);
      //}
      this.setState({ isTimedOut: false });
      
  }

  _onIdle(e) {
      //console.log('user is idle', e);
      this.setState({ showModal: true });
      setTimeout(function() {
              window.location = '/logout';    
          },
          this.state.timeout_modalInactive);


      //const isTimedOut = this.state.isTimedOut;
      //if (isTimedOut) {
      //    //console.log("now redirecting to logout");
      //    window.location = '/logout';          
      //} else {
      //    this.setState({ showModal: true });
      //    //console.log("idle timer before reset", this.idleTimer);
      //    this.setState({ timeout: this.state.timeout_modalInactive });
      //    this.idleTimer.reset();
      //    //console.log("idle timer after reset", this.idleTimer);
      //    this.setState({ isTimedOut: true });
      //}

  }
  handleClose() {
      this.setState({ showModal: false });
      //console.log("handle close - logout for now."); 
      window.location = '/logout'; 
  }

  handleLogout() {
      this.setState({ showModal: false });
      //console.log("handle logout");
      window.location = '/logout'; 
  }

  handleRefresh() {
      this.setState({ showModal: false, isTimedOut: false, timeout: this.state.timeout_userInactive });
      //console.log("handle refresh");
      authService.refreshToken();
      this.idleTimer.reset();
  }

  toggleNavbar() {
    this.setState({
        collapsed: !this.state.collapsed
    });
  }

  render() {
      const username = this.props.username;

      return (
          <nav className="navbar navbar-expand-md navbar-dark">
              <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  events={this.state.eventsToTrack}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={this.state.timeout}
              />
              <SessionExpireModal
                  showModal={this.state.showModal}
                  handleClose={this.handleClose}
                  handleRefresh={this.handleRefresh}
                  handleLogout={this.handleLogout}
              />
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                      <li className="nav-item">
                          <Link className="nav-link" to="/">Home<span className="sr-only"></span></Link>
                          {/*<NavLink tag={Link} className="btn-logout" to="/">Home</NavLink>*/}
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/submissionHistory">Submission History</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/About">About</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/HowToSubmit">How to Submit</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/Faq">FAQ</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to="/contactUs">Contact</Link>
                      </li>
                  </ul>
              </div>
          </nav>
    );
  }
}
