import React, { Component } from "react";
import Modal from '@material-ui/core/Modal';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import DateTime from "../common/DateTimeConversionMethods";

const ProposalSubmissionSuccessModal = (obj) => {
    //const solicitationResponseDeadline = obj.state.solicitation.solicitationResponseDeadline;
    const responseDeadlineDateStr = obj.state.solicitation.responseDeadlineDateLongStr;
    const responseDeadlineTimeStr = obj.state.solicitation.responseDeadlineTimeStr;
    return (
        <Dialog
            open={obj.state.confirmationModal.open === 'open'}
            onClose={obj.handleClose}
            fullWidth={true}
            maxWidth={'md'}
            className={'submitSuccess'}
            aria-labelledby="success-container"
        >
            <DialogTitle className="success-header-container" id="success-container">
                <div className="success-header-subcontainer">
                    <img alt="submission-success-icon" src="images/status-complete-circle.svg"/>
                    <div>
                        <h1 className="modal-title text-success" id="add-user-modal-label">&#8287;Success</h1>
                        <small>Submission received on</small>
                        <span className="success-datetime">{obj.state.proposal.proposalModifiedDateStr} at {obj.state.proposal.proposalModifiedTimeStr}</span>
                    </div>
                </div>
                <button type="button" className="close" onClick={obj.handleClose.bind(this)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </DialogTitle>
            <DialogContent>
                <div role="main">
                    <div>
                        <span>
                            Your upload was successful on {obj.state.proposal.proposalModifiedDateLongStr} at {obj.state.proposal.proposalModifiedTimeStr}. Your submission will appear in the <strong>Submission History </strong>section in this page and on the Submission <strong>Submission History</strong>.
                            </span>
                    </div>
                    <br/>
                    <div>
                        <span>
                            Feel free to revise your submission any time before the deadline {responseDeadlineDateStr} at {responseDeadlineTimeStr}.  You may replace any of your previously uploaded documents on this screen below.  If you don't wish to make changes now, you may find your submission to view or revise, at any time, under Submission History.
                        </span>
                    </div>
                </div>
                <br/>
                <div className="dialog-footer float-right success-footer">
                    <button type="button" className="MuiButtonBase-root MuiButton-root btn btn-primary" onClick={obj.handleClose} >Close</button>
                </div>
            </DialogContent>
        </Dialog>

    );
}

export default ProposalSubmissionSuccessModal;
