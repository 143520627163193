//(function () {
//    // external site
//    'use strict';

var analytics = {
    trackingTimeStamps: {}
},
    environments = {
        // Project specific Analytics Keys DO NOT USE IN OTHER PROJECTS!
        prod: {
            // Example-
            //     url: 'websiteaddress.com',
            //
            //     Do NOT include https://
            url: 'ecps.nih.gov',
            key: 'G-9N8TEB5VCD',
        },
        qa: {
            url: 'ecps-qa.nih.gov',
            key: 'G-NG7HNJ87TL',
        },
        stage: {
            url: 'ecps-ext-stg.niaid.nih.gov',
            key: 'G-5GX3GC4V4B',
        },
        dev: {
            url: 'ecps-ext-dev.niaid.nih.gov',
            key: 'G-EZ0FMWRQHK',
        },
    };

analytics.detectEnvironment = function () {

    for (var environment in environments) {
        if (window.location.host.toLowerCase() === String(environments[environment].url).toLowerCase()) {
            analytics.gaKey = environments[environment].key;
        }

        if (!analytics.gaKey) {
            analytics.gaKey = environments.dev.key;
        }
    }
};

analytics.setTimestamp = function (id) {
    analytics.trackingTimeStamps[id] = new Date().getTime();
};

analytics.getTimestamp = function (id) {
    return analytics.trackingTimeStamps[id];
};

analytics.initGoogleAnalytics = function () {
    //console.info('init GA4');

    function addScript(src, callback) {
        var scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', src);
        scriptElement.onload = callback;
        document.body.appendChild(scriptElement);
    }

    // Disable linting for 3rd party tracking codes
    /* eslint-disable */
    /* jshint ignore:start */
    // jscs:disable

    // Google Analytics
    function init() {
        window.dataLayer = window.dataLayer || [];
        analytics.gtag = function () { dataLayer.push(arguments); }
        analytics.gtag('js', new Date());

        analytics.gtag('config', analytics.gaKey);
    }

    addScript('https://www.googletagmanager.com/gtag/js?id=' + analytics.gaKey, init);

    // jscs:enable
    /* jshint ignore:end */
    /* eslint-enable */
};

analytics.initAnalytics = function () {
    analytics.detectEnvironment();
    analytics.initGoogleAnalytics();
};

// track current URL as page view, especially useful for SPAs.
analytics.trackPageView = function () {
    analytics.gtag('set', 'send_page_view', false);
};

// trackPage is depreciated, please use trackPageView
analytics.trackPage = function () {
    analytics.trackPageView();
};

analytics.trackEvent = function (options) {
    if (options.category && options.action && options.label) {
        analytics.gtag('event', options.action, {
            'event_category': options.category,
            'event_label': options.label,
            'value': options.value
        });
    }
};

analytics.trackTiming = function (options) {
    if (options.category && options.name && options.timeStamp) {
        analytics.gtag('event', 'timing_complete', {
            'name': options.name || 'load',
            'value': new Date().getTime() - analytics.getTimestamp(options.timeStamp),
            'event_category': options.category,
            'label': options.label
        });
    }
};

analytics.trackError = function (options) {
    if (options.description && options.fatal) {
        analytics.gtag('event', 'exception', {
            'description': options.description,
            'fatal': options.fatal || false // set to true if the error is fatal
        });
    }
};

//window.onload = function () {
//    analytics.initAnalytics();
//};
/*}());*/

export default analytics;