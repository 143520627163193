//function init() {}

//function log(error) {
//  console.log(error);
//}

//export default {
//  init,
//  log,
//};

//import { useLocation } from 'react-router-dom';


//export default (thisProps) => {
//    const location = thisProps.location; //useLocation();
//    const path = location != null ? location.pathname : "";
//    const store = window.localStorage;
//    let url = '/';
//    let prevUrl = '/';

//    url = store.getItem('url');
//    store.setItem('prevUrl', url);
//    store.setItem('url', path);

//    url = store.getItem('url');
//    prevUrl = store.getItem('prevUrl');

//    return { url, prevUrl };

//}

const sendLoginRequest = (otpSettings, localTestSettings) => {
    
    //console.log(localTestSettings.IsLocalTest)

    if (localTestSettings.IsLocalTest === 'true') {
        window.location = '/otpLogin';
    }
    else {
        var otpSite = otpSettings.OtpSiteUrl;
        var endPoint = "/connect/authorize";
        var clientId = otpSettings.OtpClientId;
        var responseType = encodeURIComponent("id_token token");
        var scope = encodeURIComponent("openid otp.user.profile");
        var returnBaseUrl = encodeURIComponent(otpSettings.ApplicationSiteUrl);
        var redirectUrl = encodeURIComponent("/otpLogin");
        var state = 1;
        var nonce = 1;
        var responseMode = "fragment";

        var resultUrl = otpSite + endPoint + "?client_id=" + clientId + "&response_type=" + responseType
            + "&scope=" + scope + "&redirect_uri=" + returnBaseUrl + redirectUrl + "&state=" + state
            + "&nonce=" + nonce + "&response_mode=" + responseMode;

        window.location.href = resultUrl;
    }  
};

const getHashFromUrl = str => {
    const query = str.substr(1);
    const result = {};

    query.split('&').forEach(function (part) {
        const item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
};

export default {
    sendLoginRequest: sendLoginRequest,
    getHashFromUrl: getHashFromUrl
}