import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SolicitationType from "../enums/SolicitationType";


export const sectionNonSpecialInvite = (obj, sol) => 
(
    <div>    
         <div className="project-banner-header">
             <div className="project-type">{sol.noticeTypeName}</div>
             <div className="solicitation-banner-right">
                 {/* {obj.props.isUserLoggedIn && false && <button className="project-invited"><span>Invited</span></button>} */}
                 {(sol.solicitationTypeId === SolicitationType.Base) ? obj.renderSamUrl(sol) : obj.renderInvitedAttachmentsButtons(sol)}
             </div>
         </div>
         <div className="project-banner-title">{sol.solicitationTitle}</div>
         <div className="project-banner-footer">
             <div className="banner-details">
                 {obj.props.isUserLoggedIn ?
                     (<div className="solicitation-action-btns external">
                         <Link to={`/submitProposal/${sol.solicitationID}`} >
                             <button className="btn">
                                 <span>Submit Proposal</span>
                             </button>
                         </Link>

                         {(sol.solicitationTypeId === SolicitationType.RTOP || sol.solicitationTypeId === SolicitationType.Other)
                             && <button className="btn delegate-staff" onClick={() => obj.handleOpenStaff(sol.solicitationID, sol.taskAreaContractID, false)}>
                                 <span>Manage Delegate Staff</span>
                             </button>}

                         {false && <div className="proposal-submitted">
                             <span>2</span>
                             <span>Submitted</span>
                         </div>}
                     </div>) :
                     (<div className="button-container">
                         <button className="MuiButtonBase-root MuiButton-root btn-white" tabIndex="0" type="button"
                             onClick={() => { window.location = '/otp/renderOtp'; }}>
                             <span className="MuiButton-label">
                                 Login to submit proposals</span>
                             <span className="MuiTouchRipple-root"></span>
                         </button>
                     </div>)
                 }
                 <div className="MuiTableContainer-root">
                     <table>
                         <thead>
                             <tr>
                                 <th scope="col" className="sr-only">Action Buttons</th>
                                 <th scope="col">Contract Specialist</th>
                                 <th scope="col">Agency</th>
                                 <th scope="col">Closing Date</th>
                             </tr>
                         </thead>
                         <tbody>
                             <tr>
                                 <td>

                                 </td>
                                 <td data-label="Contract Specialist">{sol.primaryPoc}</td>
                                 <td data-label="Agency">{sol.solicitationTargetIcAgency}/{sol.solicitationTargetIC}</td>
                                 <td data-label="Closing Date">{sol.responseDeadlineDateString} <small>{sol.responseDeadlineTimeString}</small></td>
                             </tr>
                         </tbody>
                     </table>
                 </div>
             </div>
         </div>
     </div>
)
 
                        
export const sectionSpecialInvite = (obj, sol) => 
(
    <div> 
         <div className="project-banner-header">
            <div className="project-type">{sol.noticeTypeName}</div>
            <div className="solicitation-banner-right">
                <div className="row">
                    <div>{obj.renderSpecialInvitedAttachmentsButtons(sol)}</div>
                    <div style={{ paddingRight: "15px" }}>{obj.renderSamUrl(sol)}</div>
                </div>
                
            </div>
        </div>
        <div className="project-banner-title">{sol.solicitationTitle}</div>
        {sol.specialInvites[0].baseProposalId != null &&
            <div className="project-banner-title" style={{ fontSize: "10pt" }}>
                <span style={{ opacity: "0.5" }}>Abstract</span>
                <span style={{ paddingLeft: "10px", opacity: "0.7" }}>
                    {sol.specialInvites[0].baseProposal.proposalName}
                </span>
            </div>
        }
        <div className="project-banner-footer">
            <div className="banner-details">
                {obj.props.isUserLoggedIn ?
                    (<div className="solicitation-action-btns external">
                        <Link to={`/submitSpecialProposal/${sol.solicitationID}/${sol.specialInvites[0].id}`} >
                            <button className="btn">
                                <span>Submit Proposal</span>
                            </button>
                        </Link>

                        {(sol.solicitationTypeId === SolicitationType.RTOP || sol.solicitationTypeId === SolicitationType.Other)
                            && <button className="btn delegate-staff" onClick={() => obj.handleOpenStaff(sol.solicitationID, sol.taskAreaContractID, false)}>
                                <span>Manage Delegate Staff</span>
                            </button>}

                        {false && <div className="proposal-submitted">
                            <span>2</span>
                            <span>Submitted</span>
                        </div>}
                    </div>) :
                    (<div className="button-container">
                        <button className="MuiButtonBase-root MuiButton-root btn-white" tabIndex="0" type="button"
                            onClick={() => { window.location = '/otp/renderOtp'; }}>
                            <span className="MuiButton-label">
                                Login to submit proposals</span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </div>)
                }
                <div className="MuiTableContainer-root">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" className="sr-only">Action Buttons</th>
                                <th scope="col">Contract Specialist</th>
                                <th scope="col">Agency</th>
                                <th scope="col">Closing Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>

                                </td>
                                <td data-label="Contract Specialist">{sol.primaryPoc}</td>
                                <td data-label="Agency">{sol.solicitationTargetIcAgency}/{sol.solicitationTargetIC}</td>
                                <td data-label="Closing Date">{sol.specialInvites[0].responseDeadlineDateString} <small>{sol.specialInvites[0].responseDeadlineTimeString}</small></td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
)
  
                

export default {
    sectionNonSpecialInvite,
    sectionSpecialInvite
}