import React, { Component } from 'react';
import Joi from 'joi-browser';
import auth from "../../services/authService";
import { toast } from "react-toastify";
import FileUpload from "../common/fileUpload";
import Form from "../common/form";
import HTTPStatusCodes from "../../enums/HTTPStatusCodes";
import { SolicitationHeader } from "./SolicitationHeader";
import submitProposalService from "../../services/submitProposalService";
import * as constants from "../common/constants";

import ProgressModal from './progressModal';
import Footer from "../Footer";

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        maxWidth: 300,
        fontSize: '14px',
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
    },
    arrow: {
        color: '#000',
    }
}))(Tooltip);

export class SubmitProposal extends Form {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                proposalName: ""
            },
            businessExcelFiles: [
                { id: "businessExcel1", label: "Business Excel", errorMessage: "" }
            ],
            businessDocFiles: [
                { id: "businessPDF1", label: "Business Document", isLoaded: false, errorMessage: "" }
            ],
            businessExcelFileCount: 1,
            businessDocFileCount: 1,
            solicitation: {},
            errors: {},
            pageError: false,
            previousBusinessDocFileLoaded: false,
            previousBusinessExcelFileLoaded: false,
            uploadedFiles: [],
            uploadedFileTotalSize: 0,
            headerInfo: {},
            isTechPdfLoaded: false,
            isBusinessPdfLoaded: false,
            isRequiredDocumentsUploaded: false,
            techPdfRequired: "",
            businessPdfRequired: "",
            techPdfValidationError: "",
            businessPdfValidationError: "",
            humanPdfValidationError: "",
            loading: false,
            loadedAmount: 0,
            cancelSubmission: false,
            openProgressModal: false,
            setopenProgressModal: false,
            showRadioButtons: false,
            newItemInput: "",
            selectedFastTrackValue: "",
            selectedPhase2Value: "",
            buyItems: ["Development", "Testing"],
        };

        this.schema = {
            proposalName: Joi.string().trim().required().error(() => { return { message: "Proposal Name required." }; }),
        };

        this.onFileDelete = this.onFileDelete.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.addBusinessDoc = this.addBusinessDoc.bind(this);
        this.addBusinessExcelDoc = this.addBusinessExcelDoc.bind(this);
        this.onBusinessExcelFileUpload = this.onBusinessExcelFileUpload.bind(this);
        this.onBusinessExcelFileDelete = this.onBusinessExcelFileDelete.bind(this);
        this.onBusinessDocFileUpload = this.onBusinessDocFileUpload.bind(this);
        this.onBusinessDocFileDelete = this.onBusinessDocFileDelete.bind(this);
        this.deleteFileFromTemp = this.deleteFileFromTemp.bind(this);
        this.verifyAllBusinessDocUpload = this.verifyAllBusinessDocUpload.bind(this);
    }

    async verifyAllBusinessDocUpload() {
        let isAllBusDocUploaded = true;
        let businessDocFiles = this.state.businessDocFiles;
        businessDocFiles.forEach(element => {
            if (!element.isLoaded) {
                //element.errorMessage = "Business PDF Required";
                isAllBusDocUploaded = false;
            }
        });

        return isAllBusDocUploaded;
    }
    async addBusinessDoc() {
        if (!this.state.previousBusinessDocFileLoaded) {
            return;
        }

        let businessDocFileCount = this.state.businessDocFileCount;
        businessDocFileCount++;

        let item = { id: "businessPDF" + businessDocFileCount, label: "Business Document", isLoaded: false, errorMessage: "" };

        let businessDocFiles = [...this.state.businessDocFiles, item];
        this.setState({ businessDocFileCount, businessDocFiles, previousBusinessDocFileLoaded: false, isRequiredDocumentsUploaded: false });


    }
    async addBusinessExcelDoc() {
        if (!this.state.previousBusinessExcelFileLoaded) {
            return;
        }

        let businessExcelFileCount = this.state.businessExcelFileCount;
        businessExcelFileCount++;

        let item = { id: "businessExcel" + businessExcelFileCount, label: "Business Excel", errorMessage: "" };
        let businessExcelFiles = [...this.state.businessExcelFiles, item];
        this.setState({ businessExcelFileCount, businessExcelFiles, previousBusinessExcelFileLoaded: false });

    }
    async onFileDelete(fileGuid, id) {
        //console.log("Submit Proposal File delete file id", fileGuid);
        const techPdf = "techPDF";
        const humanPdf = "humanPDF";
        const businessPdf = "businessPDF";
        try {
            this.deleteFileFromTemp(fileGuid);
            if (id === techPdf) {
                this.setState({ isTechPdfLoaded: false, isRequiredDocumentsUploaded: false });
            }
            if (id === businessPdf) {
                this.setState({ isBusinessPdfLoaded: false, isRequiredDocumentsUploaded: false });
            }
            return true;
        } catch (e) {
            //console.log("File Delete Error", e);
            return false;
        }

    }
    async deleteFileFromTemp(fileGuid) {
        const fileObjToDelete = this.state.uploadedFiles.find(o => o.fileGuid === fileGuid);

        if (fileObjToDelete !== undefined) {
            try {
                //const { data } = await submitProposalService.postSubmitProposalFileDelete(this.state.solicitation.pageGuid, fileGuid);
                const uploadedFilesFiltered = this.state.uploadedFiles.filter(obj => obj.fileGuid !== fileGuid);
                let uploadedFileTotalSize = this.state.uploadedFileTotalSize - fileObjToDelete.file.size;
                uploadedFileTotalSize = uploadedFileTotalSize || 0;
                await this.setState({ uploadedFiles: uploadedFilesFiltered, uploadedFileTotalSize });
            } catch (ex) {
                //console.log("Error in deleteFileFromTemp", ex);
                toast.error("Error Deleting File");
            }
        }
    }

    async onBusinessExcelFileDelete(fileGuid, id, isReplace) {

        try {
            if (!isReplace) {
                if (id !== "businessExcel1") {
                    let businessExcelFiles = this.state.businessExcelFiles;
                    const businessExcelFilesFiltered = businessExcelFiles.filter(obj => obj.id !== id);
                    //this.setState({
                    //    businessExcelFiles: businessExcelFilesFiltered,
                    //    previousBusinessExcelFileLoaded: true
                    //});

                    setTimeout(() => {
                        this.setState({
                            businessExcelFiles: businessExcelFilesFiltered,
                            previousBusinessExcelFileLoaded: true
                        });
                    },
                        1);

                } else {
                    this.setState({ previousBusinessExcelFileLoaded: false });
                }
            }
            this.deleteFileFromTemp(fileGuid);
            return true;

        } catch (e) {
            //console.log("Error in Business excel file delete", e);
            return false;
        }


    }
    async onBusinessDocFileDelete(fileGuid, id, isReplace) {

        try {

            if (!isReplace) {
                if (id !== "businessPDF1") {

                    let businessDocFiles = this.state.businessDocFiles;
                    const businessDocFilesFiltered = businessDocFiles.filter(obj => obj.id !== id);
                    //this.setState({ businessDocFiles: businessDocFilesFiltered, previousBusinessDocFileLoaded: true });

                    setTimeout(() => {
                        this.setState({
                            businessDocFiles: businessDocFilesFiltered,
                            previousBusinessDocFileLoaded: true
                        });
                    },
                        1);

                } else {
                    let businessDocFiles = this.state.businessDocFiles;
                    const objIndex = businessDocFiles.findIndex(obj => obj.id === id);
                    businessDocFiles[objIndex].isLoaded = false;
                    businessDocFiles[objIndex].errorMessage = "";
                    this.setState({ businessDocFiles });
                    this.setState({ previousBusinessDocFileLoaded: false, isRequiredDocumentsUploaded: false });
                }

            }

            this.deleteFileFromTemp(fileGuid);
            return true;

        } catch (e) {
            //console.log("Error in Business doc file delete", e);
            return false;
        }

    }

    async onBusinessExcelFileUpload(file, id) {
        try {
            const totalSizeAfterUpload = this.state.uploadedFileTotalSize + file.size;

            if (file.size > constants.MaxContentSizeAllowed || totalSizeAfterUpload > constants.MaxContentSizeAllowed)
                return constants.MaxContentSizeLimitExceeded;

            let businessExcelFiles = this.state.businessExcelFiles;
            const objIndex = businessExcelFiles.findIndex(obj => obj.id == id);

            var validExts = new Array(".xlsx", ".xls");
            var fileExt = file.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {

                businessExcelFiles[objIndex].errorMessage = "Only Excel files are allowed";
                this.setState({ businessExcelFiles });
                return "NotAllowed";
            }

            const fileGuid = await submitProposalService.guid();
            const item = { fileGuid, fileName: file.name, file, fileType: constants.BusinessExcel }

            let uploadedFiles = [...this.state.uploadedFiles, item];
            businessExcelFiles[objIndex].errorMessage = "";
            let uploadedFileTotalSize = this.state.uploadedFileTotalSize + file.size;
            await this.setState({ uploadedFiles, businessExcelFiles, uploadedFileTotalSize, previousBusinessExcelFileLoaded: true });

            return fileGuid;
        } catch (e) {
            //console.log(e);
            return "Error";
        }
    }

    async onBusinessDocFileUpload(file, id) {
        //console.log('on business upload');
        try {
            const totalSizeAfterUpload = this.state.uploadedFileTotalSize + file.size;
            if (file.size > constants.MaxContentSizeAllowed || totalSizeAfterUpload > constants.MaxContentSizeAllowed)
                return constants.MaxContentSizeLimitExceeded;

            let businessDocFiles = this.state.businessDocFiles;

            const objIndex = businessDocFiles.findIndex(obj => obj.id == id);

            var validExts = new Array(".xlsx", ".xls", ".pdf", ".doc", ".docx");
            var fileExt = file.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {
                businessDocFiles[objIndex].errorMessage = "Only Excel, Word or PDF files are allowed";
                businessDocFiles[objIndex].isLoaded = false;
                this.setState({ businessDocFiles });
                return "NotAllowed";
            }


            const fileGuid = await submitProposalService.guid();
            const item = { fileGuid, fileName: file.name, file, fileType: constants.NonRDBusinessDocs }
            let uploadedFiles = [...this.state.uploadedFiles, item];
            let uploadedFileTotalSize = this.state.uploadedFileTotalSize + file.size;
            await this.setState({ uploadedFiles, uploadedFileTotalSize });

            businessDocFiles[objIndex].isLoaded = true;
            businessDocFiles[objIndex].errorMessage = "";
            this.setState({ businessDocFiles });

            let isAllBusDocUploaded = await this.verifyAllBusinessDocUpload();
            this.setState({ previousBusinessDocFileLoaded: true, isRequiredDocumentsUploaded: isAllBusDocUploaded });
            return fileGuid;
        } catch (e) {
            //console.log(e);
            return "Error";
        }
    }
    async onFileUpload(file, id) {
        const techPdf = "techPDF";
        const humanPdf = "humanPDF";
        const businessPdf = "businessPDF";
        const totalSizeAfterUpload = this.state.uploadedFileTotalSize + file.size;
        if (file.size > constants.MaxContentSizeAllowed || totalSizeAfterUpload > constants.MaxContentSizeAllowed)
            return constants.MaxContentSizeLimitExceeded;
        //console.log('onFileUpload');
        try {

            var validExts = new Array(".pdf");
            var fileExt = file.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(fileExt) < 0) {

                if (id === techPdf) {
                    this.setState({ techPdfValidationError: " Only PDF file allowed", isTechPdfLoaded: false, techPdfRequired: "" });
                }

                if (id === humanPdf) {
                    this.setState({ humanPdfValidationError: " Only PDF file allowed" });
                }

                if (id === businessPdf) {
                    this.setState({ businessPdfValidationError: " Only PDF file allowed", isBusinessPdfLoaded: false, businessPdfRequired: "" });
                }
                return constants.NotAllowed;
            }

            //const { data } = await this.uploadFile(file);
            let fileType = -1;
            let isRequiredDocumentsUploaded = false;
            //TechPdf = 1, HumanPdf = 2, BusinessPdf = 3, BusinessExcel = 4
            if (id === techPdf) {
                fileType = constants.TechPdf;
                if (this.state.isBusinessPdfLoaded)
                    isRequiredDocumentsUploaded = true;
                this.setState({ isTechPdfLoaded: true, isRequiredDocumentsUploaded, techPdfRequired: "", techPdfValidationError: "" });

            }

            if (id === humanPdf) {
                fileType = constants.HumanPdf;
                this.setState({ humanPdfValidationError: "" });
            }

            if (id === businessPdf) {
                fileType = constants.BusinessPdf;
                if (this.state.isTechPdfLoaded)
                    isRequiredDocumentsUploaded = true;
                this.setState({ isBusinessPdfLoaded: true, isRequiredDocumentsUploaded, businessPdfRequired: "", businessPdfValidationError: "" });
            }
            const fileGuid = await submitProposalService.guid();
            //const item = { fileGuid: data.fileGuid, fileName: data.fileName, fileType: fileType }
            const item = { fileGuid, fileName: file.name, file, fileType: fileType }
            let uploadedFiles = [...this.state.uploadedFiles, item];
            let uploadedFileTotalSize = this.state.uploadedFileTotalSize + file.size;
            await this.setState({ uploadedFiles, uploadedFileTotalSize });

            //return data.fileGuid;   
            return fileGuid;
        } catch (e) {

            //console.log(e);
            return constants.Error;

        }
    }
    ////file upload logic changed - for external all files will be sent to server together
    //async uploadFile(file) {
    //    try {
    //        const data = new FormData();
    //        data.append('file', file);
    //        const result = await submitProposalService.postSubmitProposalFileUpload(this.state.solicitation.pageGuid, data);
    //        return result; 
    //    }
    //    catch (ex) {

    //        console.log(ex.response);
    //        toast.error(ex.response.data);
    //    } 
    //}

    async componentWillMount() {

        try {
            this.setState({ loading: true });
            const solicitationId = this.props.match.params.id;
            this.getPageHeaderInfo(solicitationId);
            const { data: solicitation } = await submitProposalService.getSolProposalSubmissionData(solicitationId);

            if (solicitation?.icId?.toUpperCase() === "SBIRSTTR" ||
                solicitation?.solicitationTargetIC?.toUpperCase() === "SBIRSTTR") {
                this.setState({ solicitation, showRadioButtons: true });
            } else {
                this.setState({ solicitation, showRadioButtons: false });
            }

            this.setState({ solicitation });
        }
        catch (ex) {
            this.setState({ pageError: true });
            setTimeout(() => window.location = "/", 3000);
        }
        finally {
            this.setState({ loading: false });
        }
    }

    async getPageHeaderInfo(solicitationId) {
        try {
            const { data: headerInfo } = await submitProposalService.getPageHeaderInfo(solicitationId);
            //console.log('fetched sol header info', headerInfo);
            this.setState({ headerInfo });

        } catch (ex) {
            console.log(ex);
        }
    }
    doSubmit = async () => {
        if (this.state.solicitation.solicitationIsRD) {
            if (!this.state.isTechPdfLoaded && !this.state.isBusinessPdfLoaded) {
                this.setState({ techPdfRequired: "Technical PDF Required", businessPdfRequired: "Business PDF Required" });
                return;
            }
            if (!this.state.isTechPdfLoaded) {
                this.setState({ techPdfRequired: "Technical PDF Required" });
                return;
            }

            if (!this.state.isBusinessPdfLoaded) {
                this.setState({ businessPdfRequired: "Business PDF Required" });
                return;
            }
        }

        if (!this.state.solicitation.solicitationIsRD) {
            let isError = false;

            let businessDocFiles = this.state.businessDocFiles;
            businessDocFiles.forEach(element => {
                if (!element.isLoaded) {
                    element.errorMessage = "Business PDF Required";
                    isError = true;
                }
            });


            this.setState({ businessDocFiles });
            if (isError) { return; }
        }

        const pageGuid = this.state.solicitation.pageGuid;
        const solicitationId = this.state.solicitation.solicitationID;
        const proposalName = this.state.data.proposalName;
        const selectedFastTrackValue = this.state.selectedFastTrackValue;
        const selectedPhase2Value = this.state.selectedPhase2Value;
        const uploadedFiles = this.state.uploadedFiles;
        const item = { proposalName, selectedFastTrackValue, selectedPhase2Value, specialInviteId: "", pageGuid, uploadedFiles }

        try {
            //console.log('inside try');
            const user = auth.getCurrentUser();
            let newThis = this;

            const res = await submitProposalService.postSubmitGateway(user, solicitationId, 1);
            if (res.status !== HTTPStatusCodes.SUCCESS) {
                toast.error("Submission gateway error");
                return;
            }

            const { data, status } = await submitProposalService.postSubmitProposal('newProposal', solicitationId, item, newThis, "api/solicitations/", "/submitProposal/", this.state.cancelSubmission);
            if (status === HTTPStatusCodes.SUCCESS) {
                //console.log("Submit Proposal success", data);
                //setTimeout(() => window.location = "/", 2000);
                setTimeout(() => {
                    this.props.history.push({
                        pathname: `/reviseProposal/${data.proposalId}`,
                        state: { showProposalSubmissionSuccessModal: true },
                        from: "Submit Proposal Page"
                    });
                    //window.location.reload();
                },
                    2000);
            } else {
                //console.log("Submit Proposal failure-data", data);
                //console.log("Submit Proposal failure-status", status);
            }
        } catch (ex) {
            if (ex.toString().indexOf("CANCEL_UPLOAD") >= 0) {
                toast.warning("Upload Canceled");
                //setTimeout(() => window.location = "/", 3000);
            }

            if (ex.response.status === 403) {
                toast.error(ex.response.data);
                setTimeout(() => window.location = "/deadlineerror", 3000);
            }
            else {
                toast.error("Submission fail");
                setTimeout(() => window.location = "/", 3000);
            }

            this.setState({
                setopenProgressModal: false,
                openProgressModal: false,
                loadedAmount: 0
            });
        }


    };

    // handleClose = () => {

    //   this
    // }

    onCancelUploading = () => {
        //console.log('onCancelUploading');
        this.setState({
            setopenProgressModal: false,
            openProgressModal: false,
            loadedAmount: 0
        });

        submitProposalService.postSubmitProposal('newProposal', null, null, null, "api/solicitations/", "/submitProposal/", true);
        submitProposalService.postSubmitGateway(auth.getCurrentUser(), this.state.solicitation.solicitationID, 3)
            .then(res => { /*console.log("User clicked Cancel button when proposal is uploading")*/ })
            .catch(err => { /*console.log("User click cancel button")*/ });
    }

    setFastTrack = (checkedValue) => {
        this.setState({
            selectedFastTrackValue: checkedValue,
        });
    };

    setPhase2 = (checkedValue) => {
        this.setState({
            selectedPhase2Value: checkedValue,
        });
    };

    resetOptions(event) {
        this.setState({
            selectedFastTrackValue: '',
            selectedPhase2Value: ''
        });
    }

    render() {
        if (this.state.pageError)
            return null;

        const submitDisabled = this.validate() || !this.state.isRequiredDocumentsUploaded;

        const loader = (<div className="loader" style={{ position: "fixed", top: "40%", left: "45%" }}>Loading...</div>);

        return (
            <div>
                <div id="content" className="content">
                    <SolicitationHeader headerInfo={this.state.headerInfo}/>
                    {/* if you're uploading and updating at the same time, maybe have the loadedAmount equal to the updating loaded + submission uploaded / 2 to show the two types of calls being made at once */}

                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">Submit Proposal <small style={{ fontSize: 14, marginLeft: 10 }}>Proposal name and documents are required to submit proposal</small></div>
                            {this.state.loading && loader}
                            {!this.state.loading && <div className="tile-section form-section submit-proposal">
                                <form onSubmit={e => { e.preventDefault(); }}>
                                    {this.renderInput("proposalName", "Proposal Name", "col-lg-9", "text", true)}

                                    {this.state.showRadioButtons &&
                                        <div className="proposal-options">
                                            <div className="options-container">
                                                <div className="form-group row">
                                                    <label className="control-label">FAST TRACK?</label>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            name="fasttrack"
                                                            value="yes"
                                                            checked={this.state.selectedFastTrackValue === 'Yes'}
                                                            onChange={this.setFastTrack.bind(this, 'Yes')}
                                                            aria-label="fasttrack yes"
                                                        />Yes
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            name="fasttrack"
                                                            value="no"
                                                            checked={this.state.selectedFastTrackValue === 'No'}
                                                            onChange={this.setFastTrack.bind(this, 'No')}
                                                            aria-label="fasttrack no"
                                                        />No
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="control-label">Direct Phase II?</label>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            name="phase2"
                                                            value="yes"
                                                            checked={this.state.selectedPhase2Value === 'Yes'}
                                                            onChange={this.setPhase2.bind(this, 'Yes')}
                                                            aria-label="phase2 yes"
                                                        />Yes
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            name="phase2"
                                                            value="no"
                                                            checked={this.state.selectedPhase2Value === 'No'}
                                                            onChange={this.setPhase2.bind(this, 'No')}
                                                            aria-label="phase2 no"
                                                        />No
                                                    </div>
                                                </div>
                                                <div className="reset-link">
                                                    <a className="link-textonly" onClick={() => this.resetOptions()}>Clear Selections</a>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </form>
                                <div className="additional-materials">
                                    <div className="material-head">
                                        <div className="material-info">
                                            <div className="material-title">Proposal Documents</div>
                                            <small>Max File Upload Size: 387.19 MB</small>
                                        </div>
                                        <div className="material-req">
                                            {!this.state.solicitation.solicitationIsRD && <p>Only excel, word or pdf formats can be submitted. Multiple individual files may be submitted.</p>}
                                            {this.state.solicitation.solicitationIsRD && <p>Only a single Technical, Business, and PHS Human Subjects and Clinical Trials PDF file can be submitted. Multiple Business Excel files may be submitted.</p>
                                            }

                                        </div>
                                    </div>
                                    <ul className="chevron-list">
                                        {this.state.solicitation.solicitationIsRD && (<li key="techPDF" className="technical-doc ">
                                            <FileUpload
                                                dynamicClass="technical-doc-upload"
                                                label="Technical PDF"
                                                id="techPDF"
                                                required={true}
                                                accept="application/pdf"
                                                errorMessage={this.state.techPdfRequired.length > 0 ? this.state.techPdfRequired : this.state.techPdfValidationError}
                                                totalUploadedFileSize={this.state.uploadedFileTotalSize}
                                                fileUpload={this.onFileUpload}
                                                fileDelete={this.onFileDelete}
                                            />

                                        </li>)}
                                        {this.state.solicitation.solicitationIsRD && this.state.solicitation.solicitationEnableOther && (<li key="humanPDF" className="misc-doc ">
                                            <FileUpload
                                                dynamicClass="technical-doc-upload"
                                                label="PHS Human Subjects and Clinical Trials PDF"
                                                id="humanPDF"
                                                required={false}
                                                accept="application/pdf"
                                                errorMessage={this.state.humanPdfValidationError}
                                                totalUploadedFileSize={this.state.uploadedFileTotalSize}
                                                fileUpload={this.onFileUpload}
                                                fileDelete={this.onFileDelete}
                                            />
                                        </li>)}
                                        {this.state.solicitation.solicitationIsRD && this.state.solicitation.solicitationEnableOther && (<li className="misc-doc-info" key="misc-doc-info"><span>Upload the Human Subjects and Clinical Trials Information Form as required in this solicitation. Refer to your solicitation instructions in <a href="https://sam.gov/content/home" className="link-textonly" target="_blank">sam.gov</a> for more information.<br />Please ensure that you are using correct version of the <a href="https://oamp.od.nih.gov/nih-document-generation-system/dgs-workform-information/attachment-files-section-j" className="link-textonly" target="_blank">Human Subject and Clinical Trial form</a>.</span></li>)}
                                        {this.state.solicitation.solicitationIsRD && (<li key="businessPDF" className="business-doc">
                                            <FileUpload
                                                dynamicClass="technical-doc-upload"
                                                label="Business PDF"
                                                id="businessPDF"
                                                required={true}
                                                accept="application/pdf"
                                                errorMessage={this.state.businessPdfRequired.length > 0 ? this.state.businessPdfRequired : this.state.businessPdfValidationError}
                                                totalUploadedFileSize={this.state.uploadedFileTotalSize}
                                                fileUpload={this.onFileUpload}
                                                fileDelete={this.onFileDelete}
                                            />
                                        </li>)}
                                        {!this.state.solicitation.solicitationIsRD && this.state.businessDocFiles.map((item) => {
                                            return (<li key={item.id} className="business-doc ">
                                                <FileUpload
                                                    dynamicClass="business-doc-upload"
                                                    label={item.label}
                                                    id={item.id}
                                                    required={true}
                                                    accept=".xlsx,.xls, .doc, .docx,.pdf"
                                                    errorMessage={item.errorMessage}
                                                    totalUploadedFileSize={this.state.uploadedFileTotalSize}
                                                    fileUpload={this.onBusinessDocFileUpload}
                                                    fileDelete={this.onBusinessDocFileDelete}
                                                />
                                            </li>);
                                        })}
                                        {this.state.solicitation.solicitationIsRD && this.state.businessExcelFiles.map((item) => {
                                            return (<li key={item.id} className="business-excel-doc ">
                                                <FileUpload
                                                    dynamicClass="technical-doc-upload"
                                                    label={item.label}
                                                    id={item.id}
                                                    required={false}
                                                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"
                                                    errorMessage={item.errorMessage}
                                                    totalUploadedFileSize={this.state.uploadedFileTotalSize}
                                                    fileUpload={this.onBusinessExcelFileUpload}
                                                    fileDelete={this.onBusinessExcelFileDelete}
                                                />
                                            </li>);
                                        })}
                                        {
                                            this.state.solicitation.solicitationIsRD && <li className="business-excel-info" key="business-excel-info"><span>Upload excel if required by the solicitation instructions. You may upload multiple excel files.</span></li>
                                        }


                                    </ul>
                                    {this.state.solicitation.solicitationIsRD && (<div className="add-doc">
                                        <button className="MuiButtonBase-root MuiButton-root MuiButton-contained success-long-btn" tabIndex="0" type="button"
                                            onClick={this.addBusinessExcelDoc} disabled={!this.state.previousBusinessExcelFileLoaded}>
                                            <span className="MuiButton-label">
                                                <img src="images/plus-white.svg" alt="add-additional-document" />Add Additional Business Excel Document
                                            </span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </button>
                                    </div>
                                    )}

                                    {!this.state.solicitation.solicitationIsRD && (<div className="add-doc">
                                        <button className="MuiButtonBase-root MuiButton-root MuiButton-contained success-long-btn" tabIndex="0" type="button"
                                            onClick={this.addBusinessDoc} disabled={!this.state.previousBusinessDocFileLoaded}>
                                            <span className="MuiButton-label">
                                                <img src="images/plus-white.svg" alt="add-additional-document" />Add Additional Business Document
                                            </span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </button>
                                    </div>
                                    )}
                                </div>
                                <div className="tile-footer" data-toggle="tooltip" title={submitDisabled ? "Required information is not provided." : ""}>
                                    {submitDisabled && <span className="document-requirement">Proposal Name and documents are required to submit proposal</span>}
                                    <button
                                        className={`MuiButtonBase-root MuiButton-root ${submitDisabled ? 'disabled-btn' : ''} MuiButton-contained submit-btn btn-primary`}
                                        tabIndex="0"
                                        type="submit"
                                        disabled={submitDisabled}
                                        onClick={(uploading) => { this.handleSubmit(uploading); this.setState({ setopenProgressModal: true, openProgressModal: true }); }}
                                    >
                                        <span className="MuiButton-label">
                                            Submit Proposal
                                        </span>
                                        <span className="MuiTouchRipple-root"></span>
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <ProgressModal
                        openProgressModal={this.state.openProgressModal}
                        setopenProgressModal={this.state.setopenProgressModal}
                        open={this.state.openProgressModal}
                        modalType='upload-progress'
                        title='Submission in progress'
                        loadedAmount={this.state.loadedAmount}
                        selectedValue={'upload progress bar'}
                        onClose={() => { this.onCancelUploading() }}
                    >
                    </ProgressModal>
                </div>
                <Footer/>
            </div >

        );
    }
}

export default SubmitProposal;
